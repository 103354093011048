import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Terms() {
  return (
    <>
      <Navi />

      <div className="page-sw   col-lg-8 col-sm-12 col-md-12">
        <>
          <h1 className="fs-2 fw-bold mb-3">
            Terms and Conditions
          </h1>
          <h5>Introduction</h5>
          <p>
            These Terms of Use govern your use of our internet and IPTV services
            ("Services"). By using our Services, you agree to these Terms of
            Use.
          </p>

          <h5>Service Terms</h5>
          <p>
            Our Services are provided as is. Users are responsible for all
            activities that occur under their account.
          </p>

          <h5>Account Registration</h5>
          <p>
            Users must provide accurate and current information during the
            registration process. Users are responsible for the security of
            their own account.
          </p>

          <h5>Payments and Billing</h5>
          <p>
            By purchasing our Services, you agree to pay the specified fees.
            Payment must be made with acceptable payment methods.
          </p>

          <h5>Use Restrictions</h5>
          <p>
            Users may not misuse our Services. Misuse includes using the
            Services for illegal purposes, or sending harmful or deceptive
            messages.
          </p>

          <h5>Content Ownership</h5>
          <p>
            All rights, title, and interest in and to the Services are and will
            remain the exclusive property of SwitchTT and its licensors.
          </p>

          <h5>Termination of Services</h5>
          <p>
            We reserve the right to suspend or terminate the Services at any
            time, with or without cause, and with or without notice.
          </p>

          <h5>Disclaimers and Limitations of Liability</h5>
          <p>
            SwitchTT will not be responsible for any damages or loss from your
            use of the Services. Your use of the Services is at your own risk.
          </p>

          <h5>Governing Law</h5>
          <p>
            These Terms of Use shall be governed by and construed in accordance
            with the laws of Trinidad and Tobago.
          </p>

          <h5>Changes to the Terms of Use</h5>
          <p>
            We may modify these Terms of Use at any time. Changes will be posted
            on this page.
          </p>

          <h5>Contact Information</h5>
          <p>
            If you have any questions or comments about these Terms of Use, you
            can contact us at: [Contact Information]
          </p>
        </>
      </div>
    </>
  );
}

export default Terms;
