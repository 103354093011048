import React, { Component, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";


import Select from 'react-select';
import countryList from 'react-select-country-list';

import SocialButton from "./SocialButton";

import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/Card";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import { useEffect } from 'react';
import jwt_decode from "jwt-decode";

import Navi from './nav';

const api = axios.create({    
    // baseURL: 'https://api.switchtt.a2hosted.com
    baseURL: 'https://switchtt.sytes.net/api'
    // baseURL: 'http://localhost:4001/api'
})

function Test() {

    useEffect(() => {

        let payment_method = {
    
            "name": "",          
            "payment_method_type": "CARD",         
            "is_primary": true,          
            "is_backup": true,          
            "notes": "",
        
            "card": {
              "card_number": paymentCCNumber,
              "card_cvc": paymentCCV,
        
              "gateway_token": [          
                  {   
                      "gateway": "STRIPE",
                      "token":""                           
                  }          
              ],
              "name": "Default Card",
              "brand": creditCardType(paymentCCNumber),          
              "first6": paymentCCNumber.substring(0, 6),          
              "last4": paymentCCNumber.substring(6, 10),         
              "expiration_month": paymentMonth,          
              "expiration_year": paymentYear,                            
      
              "card_holder_details": {          
                  "card_holder_name": paymentName,
                  "address_line_1": paymentAddress1,
                  "address_line_2": paymentAddress2,
                  "address_city": paymentCity,
                  "address_zip": paymentZip,
                  "address_state": paymentState,
                  "address_country": paymentCountry                 
              }
        
            }               
        
        }

        api.post(
            '/crmcom/contacts/' + localStorage.getItem('contact_id') + '/payment_methods',
            payment_method
        ).then(res => {
            console.log('Add payment method result: ', res.data);
        }).catch(error => {
            console.log('Add payment method error: ', error.data); 
        })


    }, []);

    return (
        
        <>
            <Navi />
            <div className="text-center">
                <h4>Testing</h4>
            </div>
            
        </>

    )

}

export default Test;