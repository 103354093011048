import {React, Component, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from './nav';

import Button from 'react-bootstrap/Button';

import ReactPlayer from 'react-player';

import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import myVideo from '../tobago.mp4';

import { useEffect } from 'react';


function Free() {
    const [counter, setCounter] = useState(15);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const goToAP =  async event => {
        await delay(15000);
        // window.location.replace("https://splash.ironwifi.com/r-9cwk9yct--venue001/");
        window.location.replace("https://www.google.com");
    }

    let videoPlayed = false;

    goToAP();

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      }, [counter]);

    return (
        <>
            <Navi />
            <div className="page-free">
                <div className="section-free">
                    <p>Your 15 Minutes of FREE Internet Access will begin in {counter} seconds.</p>
                    <ReactPlayer
                        playing
                        width='100%'
                        height='100%'
                        url={myVideo} 
                    />
                </div>
                
            </div>
            
        </>
    )

}

export default Free;