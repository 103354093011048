import React, { Component, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Select from "react-select";
import countryList from "react-select-country-list";

import SocialButton from "./SocialButton";

import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/Card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Popover from "react-bootstrap/Popover";
import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";

import { useEffect } from "react";
import jwt_decode from "jwt-decode";

import Navi from "./nav";
import Footy from "./footer";
import SwFooter from "./footer_sw";

// import { googleLogout } from "@react-oauth/google";
// import { GoogleLogin } from '@react-oauth/google';
// import { useGoogleLogin } from '@react-oauth/google';
// import { hasGrantedAllScopesGoogle } from '@react-oauth/google';

const api = axios.create({
  // baseURL: 'https://switchtt.a2hosted.com/api'
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'https://localhost:4001/api'
});

// const backToAP = () => {
//     window.location.open("https://splash.ironwifi.com/r-qm4uoduo--venue001/?_gl=1*9g5f3n*_ga*MTU4NjAyMzgyMi4xNjU3OTA1OTQw*_ga_HNYWQGR6BS*MTY2MTc3NjE4MC4zMi4xLjE2NjE3NzYyMTQuMjYuMC4w", "_blank"); //&username=" + JSON.parse(localStorage.getItem('user')).email);
// }
const backToAP = () => {
  window.location.open("https://www.google.com", "_blank");
};

const gotoTelebreeze = () => {
  // window.location.replace("https://my.telebreeze.com/user/login");
  window.open("https://my.telebreeze.com/user/login", "_blank");
};

const crm = axios.create({
  // baseURL: 'https://sandbox.crm.com/backoffice/v1',
  baseURL: process.env.REACT_APP_API_URL + "/crmcom",
  // baseURL: 'https://switchtt.a2hosted.com/api/crmcom',
  // baseURL: 'https://localhost:4001/api/crmcom',
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
  },
});

const selfservice = axios.create({
  baseURL: "https://sandbox.crm.com/self-service/v1",
  headers: {
    api_key: "637da14a-6e03-4fcb-831b-3ad1700213dd",
  },
});

// const logIn = async () => {
//     //let logoutConfirmed = confirm('Do you want to logout?');
//     const isLoggedIn = (localStorage.getItem('user') == 'empty'
//                         || localStorage.getItem('user') === null)
//                         ? 0 : 1

//     if(isLoggedIn) {

//     }

// }

// class Landing extends Component {
function Landing() {
  var md5 = require("md5");

  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userUsername, setUserUsername] = useState("");
  const [resetUsername, setResetUsername] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [authUser, setAuthUser] = useState(false);
  const [loginResponse, setLoginResponse] = useState("Login to your account");
  const [signUpResponse, setSignUpResponse] = useState(
    "Create a Switch Internet and IPTV account"
  );
  const [loginResponseBackground, setLoginResponseBackground] =
    useState("alert alert-light");
  const [user, setUser] = useState(null);
  const [authPassword, setAuthPassword] = useState("");
  const [product_activated, setActiveProduct] = useState("");
  const [activeServices, setActiveServices] = useState([]);
  const [active_product_expiration, setActiveProductExpires] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loginErrorVisible, setLoginErrorVisible] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  useEffect(() => {
    // Create a URL object from the current URL
    setLoading(true);
    const url = new URL(window.location.href);
    let token = "";
    let email = "";
    if (url.searchParams.get("access_token")) {
      // Get the token from the URL parameters
      token = url.searchParams.get("access_token");
    } else if (localStorage.getItem("authToken")) {
      // Get the token from LocalStorage
      token = localStorage.getItem("authToken");
    } else {
      token = null;
    }
    if (url.searchParams.get("access_token")) {
      email = url.searchParams.get("email");
    }

    // If there's no token, the user isn't logged in
    if (!token) {
      setIsLoggedIn(false);
      setLoading(false);
      return;
    }
    // console.log(api)
    const userData = { token: token, email: email };
    // console.log(userData);
    const config = {
      // headers: { Authorization: `Bearer ${token}` }
    };
    try {
      api
        .post(
          process.env.REACT_APP_API_URL + "/crmcom/contacts/verify",
          userData,
          config
        )
        .then((res) => {
          // If the server responds that the token is valid, log the user in
          if (res) {
            setIsLoggedIn(true);
            setActiveServices(
              JSON.parse(localStorage.getItem("active_product"))?.active_services || []
            );
            // alert('User exists')
            console.log("TRANSCEND login verification response: ", res.data);
            if (res.data.status == 400) {
              setLoginResponse(res.data.message);
              setLoginResponseBackground("alert alert-danger");
            }
            // console.log('Contact ID: ', res.data.contact && res.data.contact.id ? res.data.contact.id  : "");
            localStorage.setItem("user", JSON.stringify(res.data.contact));
            localStorage.setItem("accounts", JSON.stringify(res.data.accounts));
            localStorage.setItem(
              "contact_id",
              res.data.contact.id ? res.data.contact.id : ""
            );
            var active_product = {};
            if (res.data.active_product && res.data.active_product != {}) {
              active_product = res.data.active_product
                ? res.data.active_product
                : {};
              setActiveProduct(active_product.name ? active_product.name : "");
              console.log(res.data);

              if (res.data.status != 200) {
                // setLoginResponseBackground("alert alert-danger")
                // setLoginResponse(res.data.message)
              } else {
                // setLoginResponse(`Welcome ${res.data.contact.first_name}`)
                // setLoginResponseBackground("alert alert-success")
              }
            } else {
              active_product = {};
              // setLoginResponse(res.data.message)
            }
            // console.log(active_product.name)
            localStorage.setItem("active_product", active_product.name);
            localStorage.setItem("contact_id", res.data.contact.id);
            // console.log("++++++++++++++++++++++++++++++");
            // console.log("Setting user to true ");
            setUser(true);
            setAuthUser(false);
            // console.log("++++++++++++++++++++++++++++++");
            setLoading(false);

            // console.log(res.data)
          } else {
            setIsLoggedIn(false);
            // console.log(data)
          }
        })
        .catch((err) => {
          "Contact verification failed";
        });
    } catch (error) {
      // console.error(error);
      setLoading(false);
    }
  }, []); // This effect runs once, when the component mounts

  let passwordRequired = false;

  const gotoFree = () => {
    navigate("/free");
  };

  const gotoPlans = () => {
    navigate("/services");
  };

  // const login = useGoogleLogin({
  //     onSuccess: codeResponse => checkAccess(codeResponse),
  //     flow: 'auth-code',
  // });

  // const checkAccess = (tokenResponse) => {
  //     console.log(tokenResponse);
  //     if(hasGrantedAllScopesGoogle(
  //         tokenResponse,
  //         'email',
  //         'profile',
  //     )) {
  //         console.log('We do have access');
  //     } else {
  //         console.log('We do NOT have access');
  //     };

  // }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const waitForLoad = async (event) => {
    await delay(10000);
  };

  function handleCallbackResponse(response) {
    // console.log("Encoded JWT ID token: ", response.credential);
    // console.log("Authentication response: ", response);
    var userObject = jwt_decode(response.credential);
    // console.log('userObject: ', userObject);

    let config = {
      headers: {
        api_key: "637da14a-6e03-4fcb-831b-3ad1700213dd",
      },
    };

    let new_user = {
      first_name: userObject.given_name,
      last_name: userObject.family_name,
      email: userObject.email,
      phone_number: userPhone,
      password: "userPassword",
      provider: "GOOGLE",
      token: response.clientId,
    };

    crm.get("/contacts?search_value=" + userObject.email).then((res) => {
      // console.log('Contact search result: ', res.data);
      if (res.data.content.length == 0) {
        // console.log('Contact does not yet exist');
        api.post("/crmcom/contacts", new_user).then((res) => {
          // console.log('Contact successfully created');
          // console.log("+++++++++++++++++++++++++++++")
          // console.log(res)
          // console.log("+++++++++++++++++++++++++++++")
          // console.log('Registration result: ');
          // console.log(res.data);
          crm.get("/contacts?search_value=" + new_user.email).then((res) => {
            // console.log('Contact already exists');
            // console.log("+++++++++++++++++++++++++++++");
            // console.log(res);
            // console.log("+++++++++++++++++++++++++++++");
            // console.log('User to save: ', res.data.content[0]);
            new_user.id = res.data.content[0].id;
            localStorage.setItem("user", JSON.stringify(new_user));
            // console.log('Contact ID to save: ', res.data.content[0].id);
            localStorage.setItem("contact_id", res.data.content[0].id);
            var active_product = {};
            api
              .get("/crmcom/contacts/" + res.data.content[0].id + "/services")
              .then((res) => {
                // console.log('Fetch plans result: ', res.data);
                if (res.data.active_services.length == 0) {
                  active_product = {};
                } else {
                  active_product = res.data.active_services[0];
                }
                // console.log(active_product)
                setActiveProduct(
                  active_product.name ? active_product.name : ""
                );
                localStorage.setItem("active_product", active_product.name);
                setUser(true);
                setAuthUser(false);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
      } else {
        // console.log('Contact already exists');
        // console.log("+++++++++++++++++++++++++++++");
        // console.log(res);
        // console.log("+++++++++++++++++++++++++++++");
        // console.log('User to save: ', res.data.content[0]);
        new_user.id = res.data.content[0].id;
        localStorage.setItem("user", JSON.stringify(new_user));
        // console.log('Contact ID to save: ', res.data.content[0].id);
        localStorage.setItem("contact_id", res.data.content[0].id);
        var active_product = {};
        api
          .get("/crmcom/contacts/" + res.data.content[0].id + "/services")
          .then((res) => {
            // console.log('Fetch plans result: ', res.data);
            if (res.data.active_services.length == 0) {
              active_product = {};
            } else {
              active_product = res.data.active_services[0];
            }
            console.log(active_product.name);
            setActiveProduct(active_product.name ? active_product.name : "");
            localStorage.setItem("active_product_name", active_product.name);
            localStorage.setItem(
              "active_product",
              JSON.stringify(active_product)
            );
            setUser(true);
            setAuthUser(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // navigate('/services');
    });
  }

  const handleSocialLogin = (user) => {
    // console.log('Facebook authentication token: ', user._token);

    let config = {
      headers: {
        api_key: "637da14a-6e03-4fcb-831b-3ad1700213dd",
      },
    };

    let new_user = {
      first_name: user.profile.firstName,
      last_name: user.profile.lastName,
      email: user.profile.email,
      phone_number: userPhone,
      password: "userPassword",
      provider: "FACEBOOK",
      token: user._token,
    };

    crm
      .get("/contacts?search_value=" + user.profile.email, config)
      .then((res) => {
        // console.log('Contact search result: ', res.data);
        if (res.data.content.length == 0) {
          // console.log('Contact does not yet exist');
          api.post("/crmcom/contacts", new_user).then((res) => {
            // console.log('Contact successfully created');
            // console.log("+++++++++++++++++++++++++++++")
            // console.log(res)
            // console.log("+++++++++++++++++++++++++++++")
            // console.log('Registration result: ');
            // console.log(res.data);
            crm.get("/contacts?search_value=" + new_user.email).then((res) => {
              // console.log('Contact already exists');
              // console.log("+++++++++++++++++++++++++++++");
              // console.log(res);
              // console.log("+++++++++++++++++++++++++++++");
              // console.log('User to save: ', res.data.content[0]);
              new_user.id = res.data.content[0].id;
              localStorage.setItem("user", JSON.stringify(new_user));
              // console.log('Contact ID to save: ', res.data.content[0].id);
              localStorage.setItem("contact_id", res.data.content[0].id);
              var active_product = {};
              api
                .get("/crmcom/contacts/" + res.data.content[0].id + "/services")
                .then((res) => {
                  // console.log('Fetch plans result: ', res.data);
                  if (res.data.active_services.length == 0) {
                    active_product = {};
                  } else {
                    active_product = res.data.active_services[0];
                  }
                  // console.log(active_product.name)
                  setActiveProduct(
                    active_product.name ? active_product.name : ""
                  );
                  localStorage.setItem("active_product", active_product.name);
                  setUser(true);
                  setAuthUser(false);
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          });
        } else {
          // console.log('Contact already exists');
          // console.log("+++++++++++++++++++++++++++++");
          // console.log(res);
          // console.log("+++++++++++++++++++++++++++++");
          // console.log('User to save: ', res.data.content[0]);
          new_user.id = res.data.content[0].id;
          localStorage.setItem("user", JSON.stringify(new_user));
          // console.log('Contact ID to save: ', res.data.content[0].id);
          localStorage.setItem("contact_id", res.data.content[0].id);
          var active_product = {};
          api
            .get("/crmcom/contacts/" + res.data.content[0].id + "/services")
            .then((res) => {
              // console.log('Fetch plans result: ', res.data);
              if (res.data.active_services.length == 0) {
                active_product = {};
              } else {
                active_product = res.data.active_services[0];
              }
              // console.log(active_product.name)
              setActiveProduct(active_product.name ? active_product.name : "");
              localStorage.setItem("active_product", active_product.name);
              setUser(true);
              setAuthUser(false);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        // navigate('/services');
      });
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  function changeUserEmail({ target }) {
    setUserEmail(target.value);
  }

  function changeUserFirstName({ target }) {
    setUserFirstName(target.value);
  }

  function changeResetUsername({ target }) {
    setResetUsername(target.value);
  }

  function changeUserUsername({ target }) {
    setUserUsername(target.value);
  }

  function changeUserLastName({ target }) {
    setUserLastName(target.value);
  }

  function changeUserPassword({ target }) {
    setUserPassword(target.value);
  }

  function changeNewPassword({ target }) {
    setNewPassword(target.value);
  }

  function changeOldPassword({ target }) {
    setOldPassword(target.value);
  }

  function changeUserPhone({ target }) {
    setUserPhone(target.value);
  }

  const changeUserCountry = (value) => {
    // console.log('Country Code: ', getCountryISO3(value.value));
    setUserCountry(getCountryISO3(value.value));
  };

  function changeAuthPassword({ target }) {
    setAuthPassword(target.value);
  }

  const getCountryISO3 = require("country-iso-2-to-3");

  function updatePath(evt) {
    evt.preventDefault();
    setLoading(true);
    setErrorVisible(false);
    setSignUpResponse("Creating your account...");
    let config = {
      headers: {
        api_key: "637da14a-6e03-4fcb-831b-3ad1700213dd",
      },
      timeout: 120000,
    };

    let new_user = {
      first_name: userFirstName,
      last_name: userLastName,
      email: userEmail,
      username: userEmail,
      phone_number: userPhone,
      password: userPassword,
      // password: md5(userPassword),
      // password: md5('fireandice'),
      // password: 'fireandice',
      provider: "EMAIL",
      address: "",
      crmcom_id: "",
      iron_user_id: "",
      tele_user_id: "",
      crmcom_access_token: "",
      crmcom__token: "",
      facebook_user_token: "",
      google_user_token: "",
      device1_id: "",
    };

    // console.log(new_user);

    crm
      .get("/contacts?search_value=" + new_user.email, config)
      .then((res) => {
        // console.log('Contact search result: ', res.data);

        if (res.data.length == 0) {
          // console.log('Contact does not yet exist');
          // console.log('New Contact: ', new_user);
          api
            .post("/users", new_user, { timeout: 120000 })
            .then((res) => {
              // setProducts(res.data)
              // console.log('Create contact result: ', res.data);
              // localStorage.setItem('user', JSON.stringify(new_user));
              // setUser(new_user);

              // crm.post(
              //     '/contacts/' + res.data.id + '/payment_methods',
              //     {

              //         "name": "",

              //         "payment_method_type": "CARD",

              //         "is_primary": true,

              //         "is_backup": true,

              //         "notes": "",

              //         "card": {

              //               "gateway_token": [

              //                 {

              //                     "integration_id": "955cf6f3-e64d-4406-8adb-805e3791a2b1",
              //                     "token": "a078fd73-9e8e-4452-9e2b-b9ca0ae07d64"

              //                 }

              //             ],

              //             "name": "Default Card",

              //             "brand": "VISA",

              //             "first6": "424242",

              //             "last4": "4242",

              //             "expiration_month": 4,

              //             "expiration_year": 2029,

              //             "country_of_issue": "CYP",

              //             "card_holder_details": {

              //                 "card_holder_name": "John Doe",

              //                 "address_line_1": "Elia Papakyriakou",

              //                 "address_line_2": "7 Tower Star",

              //                 "address_city": "Nicosia",

              //                 "address_zip": "2415",

              //                 "address_state": "Nicosia",

              //                 "address_country": "CYP"

              //             }

              //         }

              //     }
              // ).then(res => {
              //     // setProducts(res.data)
              //     console.log('Create payment method result: ', res.data);
              //     localStorage.setItem('payment_method_id', res.data.id);
              // })

              // console.log('');

              // selfservice.post(
              //     '/contacts/' + res.data.id + '/credentials',
              //     {
              //         "identity": {
              //             "provider": "EMAIL",
              //             "username": userEmail,
              //             "password": userPassword,
              //             "validation_required": "false",
              //             "phone_number": userPhone,
              //             "country_code": userCountry
              //         }
              //     }
              // ).then(res => {
              //     console.log('Add contact credential result: ', res.data);
              //     localStorage.setItem('credential', JSON.stringify(res.data));
              //     // console.log('Navigating to select plan');
              //     // navigate('/services');
              // setAuthUser(true);
              // automaticLogin(new_user.email, new_user.password);
              setActiveTab(2);
              // })

              setSignUpResponse("Create a Switch Internet and IPTV account");
              setLoading(false);
              setAlertVisible(true);
            })
            .catch((err) => {
              console.log(err);
              setActiveTab(1);
              setSignUpResponse("Create a Switch Internet and IPTV account");
              setSignupError(err.message);
              setLoading(false);
              setErrorVisible(true);
            });
        } else {
          // console.log('Contact already exists');
          // setAuthUser(true);
          // setUser(new_user);
          setActiveTab(1);
          // localStorage.setItem('user', JSON.stringify(new_user));
          // localStorage.setItem('contact_id', res.data.content[0].id);
          // console.log('Navigating to select plan');
          // navigate('/services');
          setSignUpResponse("Create a Switch Internet and IPTV account");
          setLoading(false);
          setErrorVisible(true);
          setSignupError(
            "User with email address " + userEmail + " already exists"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setActiveTab(1);
        setSignUpResponse("Create a Switch Internet and IPTV account");
        setSignupError(err.message);
        setLoading(false);
        setErrorVisible(true);
      });

    // api.post('/crmcom/contacts/register', new_user).then(res => {
    //     // setProducts(res.data)
    //     console.log('Create contact result: ', res.data);
    // })

    // setUser(new_user);

    // localStorage.setItem('user', JSON.stringify(new_user));

    // navigate('services');

    // let user = {
    //     email: userEmail,
    //     firstName: userFirstName,
    //     lastName: userLastName,
    //     password: userPassword
    // }

    // console.log('Signed up user: ', user);

    // navigate(
    //     '/services',
    //     {
    //         state: {
    //             user: user,
    //             products: []
    //         },
    //          replace: false
    //     }
    // );
  }

  function checkPassword(evt) {
    evt.preventDefault();

    setLoading(true);

    let userToAuth = {
      provider: "EMAIL",
      username: user.email,
      password: authPassword,
    };

    // console.log('User to authenticate: ', userToAuth);

    api.post("/crmcom/contacts/authenticate", userToAuth).then((res) => {
      // console.log("+++++++++++++++++++++++++++++")
      // console.log(res)
      // console.log("+++++++++++++++++++++++++++++")
      // console.log('Authentication result: ');
      // console.log(res.data);
      if (res.data.status == 400) {
        setLoginResponse(res.data.message);
        setLoginResponseBackground("alert alert-danger");
      }
      // console.log('Contact ID: ', res.data.contact && res.data.contact.id ? res.data.contact.id  : "");
      localStorage.setItem("user", JSON.stringify(res.data.contact));
      localStorage.setItem("accounts", JSON.stringify(res.data.accounts));
      localStorage.setItem(
        "contact_id",
        res.data.contact.id ? res.data.contact.id : ""
      );
      var active_product = {};
      if (res.data.active_product && res.data.active_product != {}) {
        active_product = res.data.active_product ? res.data.active_product : {};
        setActiveProduct(active_product.name ? active_product.name : "");
        if (res.data.status != 200) {
          // setLoginResponseBackground("alert alert-danger")
          // setLoginResponse(res.data.message)
        } else {
          // setLoginResponse(`Welcome ${res.data.contact.first_name}`)
          // setLoginResponseBackground("alert alert-success")
        }
      } else {
        active_product = {};
        // setLoginResponse(res.data.message)
      }
      // console.log(active_product.name)
      localStorage.setItem("active_product", active_product.name);
      localStorage.setItem("contact_id", res.data.contact.id);
      // console.log("++++++++++++++++++++++++++++++");
      // console.log("Setting user to true ");
      setUser(true);
      setAuthUser(false);
      // console.log("++++++++++++++++++++++++++++++");
      setLoading(false);
    });
  }

  function automaticLogin(email, password) {
    // setLoading(true);

    let userToAuth = {
      provider: "EMAIL",
      username: email,
      password: password,
    };

    // console.log('User to authenticate: ', userToAuth);

    api
      .post("/crmcom/contacts/authenticate", userToAuth)
      .then((res) => {
        // console.log("+++++++++++++++++++++++++++++")
        // console.log('Authentication result: ');
        // console.log(res.data);
        // console.log("+++++++++++++++++++++++++++++")
        if (res.data.status == 400) {
          setLoginResponse(res.data.message);
          setLoginResponseBackground("alert alert-danger");
        }
        // console.log('Contact ID: ', res.data.contact && res.data.contact.id ? res.data.contact.id  : "");
        localStorage.setItem("user", JSON.stringify(res.data.contact));
        localStorage.setItem("accounts", JSON.stringify(res.data.accounts));
        localStorage.setItem(
          "contact_id",
          res.data.contact.id ? res.data.contact.id : ""
        );
        var active_product = {};
        if (res.data.active_product && res.data.active_product != {}) {
          active_product = res.data.active_product
            ? res.data.active_product
            : {};
          setActiveProduct(active_product.name ? active_product.name : "");
          if (res.data.status != 200) {
            // setLoginResponseBackground("alert alert-danger")
            // setLoginResponse(res.data.message)
          } else {
            // setLoginResponse(`Welcome ${res.data.contact.first_name}`)
            // setLoginResponseBackground("alert alert-success")
          }
        } else {
          active_product = {};
          // setLoginResponse(res.data.message)
        }
        // console.log(active_product.name)
        localStorage.setItem("active_product", active_product.name);
        localStorage.setItem("contact_id", res.data.contact.id);
        // console.log("++++++++++++++++++++++++++++++");
        // console.log("Setting user to true ");
        setUser(true);
        setAuthUser(false);
        // console.log("++++++++++++++++++++++++++++++");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function attemptLogin(evt) {
    evt.preventDefault();
    setLoginResponseBackground("alert alert-light");
    setLoginErrorVisible(false);

    setLoading(true);
    setLoginResponse("Signing in...");

    let userToAuth = {
      provider: "EMAIL",
      username: resetUsername,
      password: authPassword,
    };

    // Array of messages to display to the user
    let messages = [
      "Please wait, we are logging you in...",
      "Almost there, please be patient...",
      "Just a bit more...",
    ];
    // Loop over the messages
    for (let i = 0; i < messages.length; i++) {
      // Use setTimeout to delay the execution of the code inside it
      setTimeout(() => {
        setLoginResponse(messages[i]);
      }, (i + 1) * 2000); // Multiply by 2000 (which is 2 seconds) to delay each message
    }
    // console.log('User to authenticate: ', userToAuth);

    api
      .post("/crmcom/contacts/authenticate", userToAuth)
      .then((res) => {
        // console.log("+++++++++++++++++++++++++++++")
        // console.log(res)
        // console.log("+++++++++++++++++++++++++++++")
        // console.log('Authentication result: ');
        // console.log(res.data);
        if (res.data.status == 400) {
          setLoginResponse("Login Failed. Please try again later.");
          messages = []
          setLoginResponseBackground("alert alert-danger");
          setLoading(false);
        } else {
          // console.log('Contact ID: ', res.data.contact && res.data.contact.id ? res.data.contact.id  : "");
          localStorage.setItem(
            "user",
            JSON.stringify(res.data.contact ? res.data.contact : "")
          );
          localStorage.setItem(
            "accounts",
            JSON.stringify(res.data.accounts ? res.data.accounts : "")
          );
          localStorage.setItem(
            "contact_id",
            res.data.contact.id ? res.data.contact.id : ""
          );
          localStorage.setItem(
            "contact_code",
            res.data.contact_code ? res.data.contact_code : ""
          );
          var active_product = {};

          // console.log('TRANSCEND successful authentication response: ', res.data);

          if (
            res.data.active_product &&
            res.data.active_product != [] &&
            res.data.active_product.active_services &&
            res.data.active_product.active_services.length > 0 &&
            res.data.active_product.active_services[0] != {}
          ) {
            active_product = res.data.active_product
              ? res.data.active_product
              : {};
            setActiveProduct(
              active_product.active_services[0].name
                ? active_product.active_services[0].name
                : ""
            );
            setActiveServices(active_product?.active_services || []);
            setActiveProductExpires(
              active_product.active_services[0].end_date
                ? active_product.active_services[0].end_date
                : ""
            );
            localStorage.setItem(
              "contact_code",
              res.data.contact_code ? res.data.contact_code : ""
            );
            localStorage.setItem(
              "active_product_expires",
              active_product.active_services[0].end_date
                ? active_product.active_services[0].end_date
                : ""
            );
            if (res.data.status != 200) {
              // setLoginResponseBackground("alert alert-danger")
              // setLoginResponse(res.data.message)
            } else {
              // setLoginResponse(`Welcome ${res.data.contact.first_name}`)
              // setLoginResponseBackground("alert alert-success")
            }
          } else {
            active_product = {};
            // setLoginResponse(res.data.message)
          }

          // console.log('Active product name: ', (active_product.active_services && active_product.active_services[0].name) ? active_product.active_services[0].name  : "");
          // console.log('Active product Expires: ', (active_product.active_services && active_product.active_services[0].end_date) ? active_product.active_services[0].end_date  : "");

          // localStorage.setItem('active_product', {product: (active_product.active_services &&  active_product.active_services[0].name)  ? active_product.active_services[0].name  : "",
          // expires: (active_product.active_services &&   active_product.active_services[0].end_date)  ? active_product.active_services[0].end_date  : ""});

          localStorage.setItem(
            "active_product",
            JSON.stringify(active_product)
          );
          localStorage.setItem(
            "active_product_name",
            active_product.active_services &&
              active_product.active_services[0].name
              ? active_product.active_services[0].name
              : ""
          );

          localStorage.setItem(
            "service_status",
            JSON.stringify(res.data.service_status)
          );

          // setActiveProductExpires( (active_product.active_services &&  active_product.active_services[0].end_date)  ? active_product.active_services[0].end_date  : "")
          // setActiveProductExpires(active_product.active_services &&  active_product.active_services[0].end_date ? active_product.active_services[0].end_date : "");
          // console.log('Stored active product: ', localStorage.getItem('active_product_name'));
          localStorage.setItem(
            "contact_id",
            res.data.contact && res.data.contact.id ? res.data.contact.id : ""
          );
          setUser(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoginErrorVisible(true);
        messages = []
        // console.log('TRANSCEND login error: ', err.response.data.message.message);
        // setLoginError(err.response.data.message.message);
        setLoginError("Login failed. Please try again.");
        setLoginResponse("");
        setLoading(false);
      });
  }

  function resetPassword(evt) {
    evt.preventDefault();

    selfservice
      .post("/contacts/authenticate", {
        provider: "EMAIL",
        username: resetUsername,
        password: oldPassword,
      })
      .then((res) => {
        // console.log('Authentication result: ', res.data);
        // localStorage.setItem('user', JSON.stringify(res.data.contact));
        // localStorage.setItem('contact_id', res.data.contact.id);
        // navigate('/services');
        api
          .post("/users/" + res.data.contact.id + "/change_password", {
            password: newPassword,
          })
          .then((res) => {
            // console.log('Password reset result: ', res.data);
          });
      });
  }

  useEffect(() => {
    let isMounted = true; // track whether component is mounted

    // console.log('Active product in the beginning: ', localStorage.getItem('active_product'));
    var referrer = document.referrer;
    var host = window.location.host;
    // console.log('Active product before if: ', localStorage.getItem('active_product'));
    if (!localStorage.hasOwnProperty("active_product")) {
      // console.log('No active product detected');
      // console.log('Active product inside if: ', localStorage.getItem('active_product'));
      setActiveProduct("");
    } else {
      // console.log('Active product detected: ', localStorage.getItem('active_product'));
      // console.log('Active product inside if: ', localStorage.getItem('active_product'));
      if (localStorage.getItem("active_product") === "undefined") {
        // console.log('Active product IS an empty string');
        setActiveProduct("");
      } else {
        // console.log('Active product is NOT empty string "' + localStorage.getItem('active_product_name') + '"');
        setActiveProduct(localStorage.getItem("active_product_name"));
        setActiveProductExpires(localStorage.getItem("active_product_expires"));
        // console.log('TRANSCEND setting active services', JSON.parse(localStorage.getItem('active_product')).active_services);
        setActiveServices(
          JSON.parse(localStorage.getItem("active_product"))?.active_services || []
        );
      }
    }
    // console.log('Active product after if: ', localStorage.getItem('active_product'));

    if (referrer.split("/")[2] == host) {
      // console.log('Referrer and current host are the same so referrer is NOT valid ');
      // console.log('access-point: ', localStorage.getItem('access-point'));
    } else {
      // console.log('Referrer and current host are not the same so referrer IS valid ');
      localStorage.setItem("access-point", referrer.split("/")[2]);
      // console.log('access-point: ', localStorage.getItem('access-point'));
    }

    // window.google.accounts.id.initialize({
    //     client_id: "791796628970-7c4jtm8fo54o6ftgb7qdag12iden3ta4.apps.googleusercontent.com",
    //     callback: handleCallbackResponse
    // });

    waitForLoad();
    // window.google.accounts.id.renderButton(
    //     document.getElementById("signInDiv"),
    //     {theme: OfflineAudioCompletionEvent, size: "large"}
    // );

    if (
      !localStorage.getItem("user") ||
      localStorage.getItem("user") == "empty" ||
      localStorage.getItem("user") === null
    ) {
      // console.log("======================================")
      // localStorage.setItem('user', 'empty');
      // console.log(localStorage.getItem('user'))
      // console.log("======================================")
    } else {
      setUser(JSON.parse(localStorage.getItem("user")));
      console.log("TRANSCEND first useEffect");
      try {
        console.log("TRANSCEND inside refresh try block");
        crm
          .get("/refresh")
          .then((res) => {
            console.log("TRANSCEND user refresh response: ", res.data);
            let current_active_product = JSON.parse(
              localStorage.getItem("active_product")
            );
            current_active_product.active_services =
              res.data.active_plans.active_services;
            localStorage.setItem(
              "active_product",
              JSON.stringify(current_active_product)
            );
            setActiveServices(current_active_product?.active_services || []);
            localStorage.setItem(
              "service_status",
              JSON.stringify(res.data.service_status)
            );
          })
          .catch((err) => {
            console.log("TRANSCEND user refresh err: ", err);
          });
      } catch (error) {
        // console.error(error);
        // setLoading(false);
      }
      // console.log('TRANSCEND after refresh try block');
    }
  }, []);

  if (!authUser) {
    // if(localStorage.getItem('user') == 'empty' || localStorage.getItem('user') === null) {
    if (user == null) {
      return (
        <>
          <div>
            <Navi />
          </div>
          <div className="page-landing col-sm-12 col-md-12 col-lg-6">
            <Tabs
              //defaultActiveKey={2}
              className="mb-3"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              id="controlled-tab-example"
            >
              <Tab eventKey={1} title="Sign Up">
                <div className="container ">
                  <div className="row iki-landing-form">
                    <Form onSubmit={updatePath}>
                      <div className="iki-form-desc-a text-center">
                        {signUpResponse}
                      </div>

                      {errorVisible && (
                        <Alert key="danger" variant="danger">
                          <Alert.Heading>
                            Error creating your account
                          </Alert.Heading>
                          <p>{signupError}</p>
                        </Alert>
                      )}

                      {loading ? (
                        <Row className="text-center">
                          <Col>
                            <div
                              className="page-loading iki-spinner spinner-border"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <div className="iki-form-container">
                            <Form.Group
                              className="iki-row-item-a"
                              controlId="formBasicFirstName"
                            >
                              {/* <Form.Label>First Name</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder="First Name"
                                value={userFirstName}
                                onChange={changeUserFirstName}
                                required
                              />
                            </Form.Group>

                            <Form.Group
                              className="iki-row-item-a"
                              controlId="formBasicLastName"
                            >
                              {/* <Form.Label>Last Name</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder="Last Name"
                                value={userLastName}
                                onChange={changeUserLastName}
                                required
                              />
                            </Form.Group>
                          </div>

                          <div className="iki-form-container">
                            <Form.Group
                              className="iki-row-item-a"
                              controlId="formBasicEmail"
                            >
                              {/* <Form.Label>Email address</Form.Label> */}
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                value={userEmail}
                                onChange={changeUserEmail}
                                required
                              />
                            </Form.Group>
                          </div>

                          <div className="iki-form-container">
                            <Form.Group
                              className="iki-row-item-b"
                              controlId="formBasicCountry"
                            >
                              {/* <Form.Label>Country</Form.Label> */}

                              <Select
                                options={options}
                                placeholder="Country"
                                onChange={changeUserCountry}
                                required
                              />
                            </Form.Group>
                            <Form.Group
                              className="iki-row-item-a"
                              controlId="formBasicPhone"
                            >
                              {/* <Form.Label>Phone</Form.Label> */}
                              <Form.Control
                                type="phone"
                                placeholder="555 5555"
                                value={userPhone}
                                onChange={changeUserPhone}
                                required
                              />
                            </Form.Group>
                          </div>

                          <div className="iki-form-container">
                            <Form.Group
                              className="iki-row-item-a"
                              controlId="formBasicPassword"
                            >
                              {/* <Form.Label>Password</Form.Label> */}
                              <Form.Control
                                type="password"
                                placeholder="Password"
                                value={userPassword}
                                onChange={changeUserPassword}
                                required
                                minLength="6"
                              />
                            </Form.Group>
                          </div>

                          <div className="iki-form-container iki-form-button">
                            <div className="iki-row-item-button">
                              <Button variant="primary" type="submit" size="lg">
                                Sign Up
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
              </Tab>

              <Tab eventKey={2} title="Log In">
                <Form onSubmit={attemptLogin}>
                  {alertVisible && (
                    <Alert key="info" variant="info">
                      <Alert.Heading>Confirmation email sent</Alert.Heading>
                      <p>
                        An email confirmation email has been send to your email
                        address {userEmail}. Please confirm your email address
                        there before attempting to login
                      </p>
                    </Alert>
                  )}

                  <div className="iki-form-container-login ">
                    <div className="text-center mb-3">
                      <div className={""} role="alert">
                        {loginResponse}
                      </div>
                    </div>

                    {loginErrorVisible && (
                      <Alert key="danger" variant="danger">
                        {/* <Alert.Heading>Error logging in</Alert.Heading> */}
                        <p className="text-center p-0 m-0" >{loginError}</p>
                      </Alert>
                    )}

                    <>
                      {loading ? (
                        <Row className="text-center">
                          <Col>
                            <div
                              className="page-loading iki-spinner spinner-border"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </>
                    {!loading ? (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicUsername"
                        >
                          {/* <Form.Label>Email</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="Email"
                            value={resetUsername}
                            onChange={changeResetUsername}
                            required
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formAuthPassword"
                        >
                          {/* <Form.Label>Password</Form.Label> */}
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={authPassword}
                            onChange={changeAuthPassword}
                            required
                          />
                        </Form.Group>

                        <div className="iki-row-item-button">
                          <Button variant="primary" type="submit" size="lg">
                            Login
                          </Button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </Tab>
            </Tabs>

            {!loading ? (
              <>
                <div className="iki-reset-password text-center">
                  <Link className="btn clear btn-link" to="/forgot">
                    Forgot Password?
                  </Link>
                </div>

                {/* <div className="iki-form-container">
                            <div className="iki-row-item-button social-btns btn-fb">
                                <div className="fb-button">
                                    <SocialButton
                                        provider="facebook"
                                        appId="1118096102391004"
                                        onLoginSuccess={handleSocialLogin}
                                        onLoginFailure={handleSocialLoginFailure}
                                        >
                                        Login with Facebook
                                    </SocialButton>
                                </div>

                                <div className="iki-row-item-button">
                                    <div id="signInDiv"></div>
                                </div>
                            </div>
                            
                        </div> */}
              </>
            ) : (
              ""
            )}

            <div className="d-grid gap-2">
              {/* <GoogleLogin
                                onSuccess={credentialResponse => {
                                    console.log(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />

                            <Button onClick={() => login()}>
                            Sign in with Google 🚀{' '}
                            </Button> */}
            </div>
          </div>
          {/* {<Footy />}
          {<SwFooter />} */}
        </>
      );
    } else {
      let user = JSON.parse(localStorage.getItem("user"));

      if (activeServices.length == 0) {
        return (
          <>
            <Navi />

            <div className="page-landing col-lg-6 col-sm-12 col-md-6">
              <div className="row text-center">
                {/* <h6>Welcome {user.first_name + ' ' + user.last_name}</h6> */}
                <h4 className="text-center iki-welcome-name">
                  Welcome {user.first_name}
                </h4>
              </div>
              <div className="iki-choose-plan text-center">
                <h5>Choose one of our exciting Internet and IPTV plans </h5>
                <Button className="btn btn-lg" onClick={gotoPlans}>
                  Select Plan
                </Button>
              </div>

              <div className="iki-choose-plan text-center">or</div>
              <div className="iki-choose-plan text-center">
                <h5>Enjoy 15 minutes of FREE Internet Access</h5>
                <div>
                  <Button
                    className="btn btn-lg btn-secondary"
                    onClick={gotoFree}
                  >
                    Free Internet Access
                  </Button>
                </div>
              </div>

            </div>
          </>
        );
      } else {
        return (
          <>
            <Navi />
            <div className="page-landing col-lg-6 col-sm-12 col-md-12">
              <div className="row text-left">
                <h3 className="m-0 mb-4 fs-4 text-center">
                  Welcome, {user.first_name + " " + user.last_name}
                </h3>

                <div className="iki-login-options container-fluid text-center mr-1 ml-1 d-flex p-0 align-items-stretch justify-content-around">
                  <div className="col-6 pr-2 d-flex align-items-center justify-content-center">
                    <Button
                      size="lg"
                      className=" sw-btn sw-btn-r"
                      onClick={backToAP}
                    >
                      <i className="mdi fs-1 mdi-wifi sw-btn"></i>
                      <br />
                      Connect to Internet
                    </Button>
                  </div>
                  <div className="col-6 pl-2 d-flex align-items-center justify-content-center">
                    {/* { (JSON.parse(localStorage.getItem('product')).type.id == '0b74aa86-ece7-4cc3-82b8-2cf657b5e8dc' || JSON.parse(localStorage.getItem('product')).type.id == '3737190a-f3ab-40ae-8823-0ae2a8d8c5c1') ? <Button onClick={gotoTelebreeze}>Login to IP TV</Button> : '' } */}
                    <Button
                      size="lg"
                      className=" sw-btn sw-btn-l"
                      onClick={gotoTelebreeze}
                    >
                      <i className="mdi fs-1 mdi-cast"></i>
                      <br />
                      Login to IPTV{" "}
                    </Button>
                  </div>
                </div>

                <ListGroup className="p-0 mt-2 sw-current">
                  {activeServices.map((service) => (
                    <ListGroupItem
                      className="m-0 p-0 border-0"
                      key={service.product_id}
                    >
                      <div className="iki-active-plan m-3 mt-0 pe-1 ps-1 pt-2 pb-2">
                        <div className="mb-0 d-flex">
                          <Col xs={6} className="text-left">
                            <div className="iki-landing-expires">
                              <span>Active Plan</span>
                            </div>
                          </Col>
                          <Col xs={6} className="text-right">
                            <div className="iki-landing-expires text-center">
                              Expires: {service.end_date}
                            </div>
                          </Col>
                        </div>

                        <div className="plan-title fs-4 text-center text-sm small">
                          <span>{service.name}</span>
                        </div>
                        {/* <div className="plan-desc">
                                                    <div className="plan-desc-a">Your subscription includes:</div>
                                                    <div className="plan-desc-b">??</div>
                                                </div>  */}
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            </div>
            <div className="iki-sw-footer">
              {/* {<Footy />}
              {<SwFooter />} */}
            </div>
          </>
        );
      }
    }
  } else {
    return (
      <div>
        <Navi />

        {loading ? (
          <Row className="text-center">
            <Col>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <div className="iki-form-desc text-center">
          <div className={loginResponseBackground} role="alert">
            {loginResponse}
          </div>
        </div>

        <Form onSubmit={checkPassword}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={authPassword}
              onChange={changeAuthPassword}
              minlength="6"
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="primary" type="submit" size="lg">
              Login
            </Button>
          </div>
        </Form>

        {/* {<Footy />}
        {<SwFooter />} */}
      </div>
    );
  }
}

export default Landing;
