import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import Navi from "./nav";
import Footy from "./footer";
import ProgressBar from "./progressBar";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import "../checkout.css";
import axios from "axios";
axios.defaults.withCredentials = true;

const PowerTranzHost = process.env.REACT_APP_POWERTRANZ_HOST;

const crm = axios.create({
  //baseURL: 'https://sandbox.crm.com/backoffice/v1',
  // baseURL: 'https://switchtt.sytes.net/api/crmcom',
  baseURL: process.env.REACT_APP_API_URL + "/crmcom",
  // baseURL: "https://localhost:4001/api/crmcom",
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
    // api_key: "637da14a-6e03-4fcb-831b-3ad1700213dd",
  },
});

const backToAP = () => {
  // setData("Data is set");
  // window.location.replace('https://' + localStorage.getItem('access-point'));
  window.location.replace(
    "https://splash.ironwifi.com/r-qm4uoduo--venue001/?_gl=1*9g5f3n*_ga*MTU4NjAyMzgyMi4xNjU3OTA1OTQw*_ga_HNYWQGR6BS*MTY2MTc3NjE4MC4zMi4xLjE2NjE3NzYyMTQuMjYuMC4w"
  ); //&username=" + JSON.parse(localStorage.getItem('user')).email);
};

function makeAuthRequest() {
  const url = PowerTranzHost;

  const headers = {
    Accept: "application/json",
    "PowerTranz-PowerTranzId": "#PowerTranzPasswordId#",
    "PowerTranz-PowerTranzPassword": "#PowerTranzPassword#",
    "Content-Type": "application/json; charset=utf-8",
    Host: "staging.ptranz.com",
    Expect: "100-continue",
    Connection: "Keep-Alive",
  };

  const body = {
    TransactionIdentifier: "5ee7d7c1-af78-4d7e-9386-abb280822b73",
    TotalAmount: 1,
    CurrencyCode: "978",
    ThreeDSecure: true,
    Source: {
      CardPan: "5115010000000001",
      CardCvv: "",
      CardExpiration: "2512",
      CardholderName: "John Doe",
    },
    OrderIdentifier: "INT-95e75078-7d58-40e8-8053-c3d488f05f59-Orc 3569",
    BillingAddress: {
      FirstName: "John",
      LastName: "Smith",
      Line1: "1200 Whitewall Blvd.",
      Line2: "Unit 15",
      City: "Boston",
      State: "NY",
      PostalCode: "200341",
      CountryCode: "840",
      EmailAddress: "john.smith@gmail.com",
      PhoneNumber: "211-345-6790",
    },
    AddressMatch: false,
    ExtendedData: {
      ThreeDSecure: {
        ChallengeWindowSize: 4,
        ChallengeIndicator: "01",
      },
      MerchantResponseUrl: "https://localhost:5001/Final",
    },
  };

  axios
    .post(url, body, { headers: headers })
    .then((response) => {
      console.log(response.status);
      console.log(response.data);
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
    });
}

const api = axios.create({
  // https://api.switchtt.a2hosted.com
  // baseURL: 'https://switchtt.sytes.net/api',
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://localhost:4001/api",
});

const user_missing =
  localStorage.getItem("user") == "empty" ||
  localStorage.getItem("user") === null;

const Checkout = () => {
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  const activeStep = 2;

  const hideNoCard = () => {
    setShowCardUnselected(false);
  };

  const [loaded, setLoaded] = useState(false);

  const getCountryISO3 = require("country-iso-2-to-3");
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "pk_test_51GrQ8HDhgyRWLej3f3QOlHlNjkrr55NV1z4zDwR8I6wioivwv3ES9x7a4JPH3xY6LOCXBUI1IHee0tv4uO3uSlMq00wtUVA7Cu",
  };

  const CurrentYear = new Date().getFullYear();

  const [paymentName, setPaymentName] = useState("");
  const [paymentCCNumber, setPaymentCCNumber] = useState("");
  const [paymentMonth, setPaymentMonth] = useState("01");
  // const [paymentYear, setPaymentYear] = useState("");
  const [paymentYear, setPaymentYear] = React.useState(
    String(CurrentYear).slice(-2)
  );
  const [confirmationResponse, setConfirmationResponse] = useState("");
  const [showItems, setShowItems] = useState(false);
  const [confirmationResponseBackground, setConfirmationResponseBackground] =
    useState("alert alert-light");

  const [paymentCCV, setPaymentCCV] = useState("");
  const [paymentAddress1, setPaymentAddress1] = useState("");
  const [paymentAddress2, setPaymentAddress2] = useState("");
  const [paymentCity, setPaymentCity] = useState("");
  const [paymentState, setPaymentState] = useState("");
  const [paymentZip, setPaymentZip] = useState("");
  const [paymentCountry, setPaymentCountry] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentCards, set_cards] = useState([]);
  const [counter, setCounter] = useState(10);
  const [show, setShow] = useState(false);
  const [showFrame, setShowFrame] = useState(false);
  const [showAddNewCard, setShowAddNew] = useState(false);
  const [showCardError, setshowCardError] = useState(false);
  const [hidePayButton, setHidePayButton] = useState(true)

  const [height, setHeight] = useState("300px"); // initial height
  const [loading, setLoading] = useState(false);
  const [currentCard, setCurrentCard] = useState({});
  const [srcDoc, setSrcDoc] = useState("");

  const [browserInfo, setBrowserInfo] = useState({});
  const [error, setError] = useState(null);

  const handleCloseFrame = () => setShowFrame(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShowAddNew(false);
    setShow(true);
  };

  const handleCloseAddNew = () => {
    setShow(true);
    setShowAddNew(false);
  };
  const handleShowAddNew = () => {
    setShow(false);
    setShowAddNew(true);
  };

  const handleShowFrame = () => {
    setShowAddNew(false);
    setShowFrame(true);
  };

  const [activeTab, setActiveTab] = useState(1);

  const [active, setActive] = useState(-1);

  const [cardSelected, setCardSelected] = useState(false);
  const [showCardUnselected, setShowCardUnselected] = useState(false);

  function doCheckout(evt) {
    setLoading(true);

    console.log(
      "Stored product: ",
      JSON.parse(localStorage.getItem("product")).pricing.id
    );
    console.log("Stored user: ", JSON.parse(localStorage.getItem("user")));
    console.log(
      "Stored accounts: ",
      JSON.parse(localStorage.getItem("accounts"))
    );
    console.log(
      "Stored payment method: ",
      JSON.parse(localStorage.getItem("payment_method"))
    );
    let price_terms_id = JSON.parse(localStorage.getItem("product")).pricing.id;

    console.log("Create Order Estimate result: ");
    if (!localStorage.getItem("tid") || !localStorage.getItem("oid")) {
      return;
    }
    api
      .post("/crmcom/orders", {
        contact_id: localStorage.getItem("contact_id") || "",
        estimation_id: localStorage.getItem("tid") || "",
        payments: [
          {
            amount:
              parseInt(
                JSON.parse(localStorage.getItem("product"))?.pricing?.price
              ) || 0,
            payment_method: {
              type: "CARD",
              id: JSON.parse(localStorage.getItem("payment_method")).id,
            },
          },
        ],
        notes: "",
      })
      .then((res) => {
        console.log(res.data);
        // return
        setSrcDoc(res.data);
        const timer = setTimeout(() => {
          // setLoading(false);
          setShowFrame(true);
        }, 1000);

        // navigate("/confirmation");
      });

    // return;

    // experimental to load the iframe
    //send a call to the face test on local host
    // crm
    //   .post(
    //     "https://localhost:4001/fac/authWithTokenRequest",
    //     {
    //       account_id: JSON.parse(localStorage.getItem("accounts"))[0].id,
    //       supply_method: "DIRECT_SALE",
    //       fullfilled_by: "",
    //       use_wallet_funds: "false",
    //       payment_method_type: "CARD",
    //       line_items: [
    //         {
    //           id: JSON.parse(localStorage.getItem("product")).id,
    //           quantity: 1,
    //           price: "75",
    //           tax_model: "TAX_INCLUSIVE",
    //           notes: "",
    //           price_terms_id: "price_terms_id",
    //         },
    //       ],
    //       browserInfo: browserInfo
    //     },
    //     {
    //       withCredentials: true,
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     return
    //     setSrcDoc(res.data);
    //     const timer = setTimeout(() => {
    //       setLoading(false);
    //       setShowFrame(true);
    //     }, 1000); // Delay for 1 second(s)

    //     // setLoading(false);
    //     return;
    //   })
    //   .catch((error) => {
    //     console.log("Error: ", error);
    //     setLoading(false);
    //   });

    //inject the data into the iframe

    ///////////////////////////////////

    return;

    // console.log(
    //   "Stored product: ",
    //   JSON.parse(localStorage.getItem("product")).pricing.id
    // );
    // console.log("Stored user: ", JSON.parse(localStorage.getItem("user")));
    // console.log(
    //   "Stored accounts: ",
    //   JSON.parse(localStorage.getItem("accounts"))
    // );
    // console.log(
    //   "Stored payment method: ",
    //   JSON.parse(localStorage.getItem("payment_method"))
    // );
    // let price_terms_id = JSON.parse(localStorage.getItem("product")).pricing.id;

    // crm
    //   .post("/estimates/orders", {
    //     account_id: JSON.parse(localStorage.getItem("accounts"))[0].id,
    //     supply_method: "DIRECT_SALE",
    //     fullfilled_by: "",
    //     use_wallet_funds: "false",
    //     payment_method_type: "CARD",
    //     line_items: [
    //       {
    //         id: JSON.parse(localStorage.getItem("product")).id,
    //         quantity: 1,
    //         price: "75",
    //         tax_model: "TAX_INCLUSIVE",
    //         notes: "",
    //         price_terms_id: price_terms_id,
    //       },
    //     ],
    //   })
    //   .then((res) => {
    //     console.log("Create Order Estimate result: ", res.data);
    //     if (res.data.status != 200) {
    //       setConfirmationResponseBackground("alert alert-danger");
    //       setConfirmationResponse(res.data.message);
    //     }
    //     api
    //       .post("/crmcom/orders", {
    //         estimation_id: res.data.id,
    //         payments: [
    //           {
    //             payment_method_type: "CARD",
    //             paid_on: "ON_ORDER",
    //             payment_method_id: JSON.parse(
    //               localStorage.getItem("payment_method")
    //             ).id,
    //             amount: 75.0,
    //             payment_token: "",
    //           },
    //         ],
    //         notes: "",
    //         use_wallet_funds: "false",
    //       })
    //       .then((res) => {
    //         setLoading(false);
    //         if (res.data.status != 200) {
    //           setConfirmationResponseBackground("alert alert-danger");
    //           setConfirmationResponse(res.data.message);
    //         }
    //         console.log("Order Creation result : ", res.data);
    //         localStorage.setItem("order_id", res.data.id);
    //         console.log("Navigating to confirmation");
    //         navigate("/confirmation");
    //       });
    //   })
    //   .catch((error) => {
    //     console.log("Create Order Estimate error: ", error.response.data);
    //     setConfirmationResponseBackground("alert alert-danger");
    //     setConfirmationResponse(res.data.message);
    //     setLoading(false);
    //   });
  }

  const goBack = () => {
    navigate("/services");
  };

  function save_all_cards(payment_mehthods) {
    const all_payment_mehtods = payment_mehthods;
    console.log(all_payment_mehtods);
    set_cards(all_payment_mehtods);
    goToSavedCards();
  }

  function goToSavedCards() {
    setActiveTab(1);
  }

  useEffect(() => {
    try {
      const BrowserInfo = {
        AcceptHeader: window.navigator.languages[0] || "N/A",
        Language: window.navigator.language || "N/A",
        ScreenHeight: window.screen.height || "N/A",
        ScreenWidth: window.screen.width || "N/A",
        TimeZone: new Date().getTimezoneOffset() / -60 || "N/A",
        UserAgent: window.navigator.userAgent || "N/A",
        IP: "Not accessible from client side",
        JavaEnabled: window.navigator.javaEnabled() ? "True" : "False",
        JavascriptEnabled: true,
        ColorDepth: window.screen.colorDepth || "N/A",
      };
      setBrowserInfo(BrowserInfo);
      console.log(BrowserInfo);
      // return
    } catch (err) {
      setError(err.message);
    }
  }, []);

  useEffect(() => {
    if (!user_missing) {
      //get user payment methods
      // api
      //   .get(
      //     "/crmcom/contacts/" +
      //       JSON.parse(localStorage.getItem("user")).id +
      //       "/payment_methods"
      //   )
      //   .then((res) => {
      //     console.log("Get contacts payment methods results: ", res.data);
      //     setPaymentMethods(res.data);
      //     set_cards(res.data);
      //     setHidePayButton(false)
      //     // setSelectedItem(res.data[0])
      //   });

      //counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      const url = process.env.REACT_APP_API_URL + "/crmcom/contacts/";
      const x = axios
        .get(
          url + JSON.parse(localStorage.getItem("user")).id + "/payment_methods"
        )
        .then((res) => {


          console.log("Get contacts payment methods results: ", res.data);
          setPaymentMethods(res.data);
          set_cards(res.data);
          setHidePayButton(false)
          // setSelectedItem(res.data[0])



          // console.log(res.data.content)
          setLoaded(true);
          //return
          //setPaymentMethods(res.data.content);
          if (res.data.length > 0) {
            // set_cards(res.data);
            console.log(res.data[0].is_primary);
            setCurrentCard(res.data[0]?.card || {});
            setSelectedItem(res.data[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      api
        .get(
          "/crmcom/contacts/" +
            JSON.parse(localStorage.getItem("user")).id +
            "/services"
        )
        .then((res) => {
          if (!product_missing) {
            // if(typeof res.data.active_services.find(o => o.product_id === JSON.parse(localStorage.getItem('product')).id) === 'undefined') {
            //   console.log('Active plan selected for purchase');
            //   navigate('/services');
            // } else {
            //   console.log('Plan selected for purchase is not active');
            // }
          }
        });
    }
  }, []);

  const countries = useMemo(() => countryList().getData(), []);
  // console.log('Stored access-point: ', localStorage.getItem('access-point'));
  // console.log('Stored product: ', JSON.parse(localStorage.getItem('product')));

  const changePaymentName = (e) => {
    setPaymentName(e.target.value);
    //console.log(ac_value);
  };

  function changePaymentCCNumber({ target }) {
    setPaymentCCNumber(target.value);
  }

  function changePaymentMonth({ target }) {
    setPaymentMonth(target.value);
  }

  const changePaymentYear = (event) => {
    setPaymentYear(event.target.value);
  };

  function changePaymentCCV({ target }) {
    setPaymentCCV(target.value);
  }

  function changePaymentCity({ target }) {
    setPaymentCity(target.value);
  }

  function changePaymentState({ target }) {
    setPaymentState(target.value);
  }

  function changePaymentZip({ target }) {
    setPaymentZip(target.value);
  }

  function changePaymentAddress2({ target }) {
    setPaymentAddress2(target.value);
  }

  function changePaymentAddress1({ target }) {
    setPaymentAddress1(target.value);
  }

  const setSelectedItem = (payment_method) => {
    setActive(payment_method.id);
    console.log(payment_method);
    setCurrentCard(payment_method.card);
    setShow(false);
    // setCurrentCard(res.data.content[0]?.card || {});
    setCardSelected(true);
    setShowCardUnselected(false);
    localStorage.setItem("payment_method", JSON.stringify(payment_method));
  };

  const save_all_payment_methods = (saved_payment_method) => {
    localStorage.setItem(
      "saved_payment_methods",
      JSON.stringify(saved_payment_method)
    );
  };

  const proceed_to_order = () => {
    if (cardSelected) {
      // console.log(JSON.parse(localStorage.getItem('saved_payment_methods')));
      navigate("/estimate");
    } else {
      setShowCardUnselected(true);
    }
  };

  const changePaymentCountry = (value) => {
    // console.log('Country Code: ', getCountryISO3(value.value));
    setPaymentCountry(getCountryISO3(value.value));
  };

  function creditCardType(cc) {
    let amex = new RegExp("^3[47][0-9]{13}$");
    let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
    let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
    let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

    let mastercard = new RegExp("^5[1-5][0-9]{14}$");
    let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

    let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
    let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
    let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

    let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
    let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

    if (visa.test(cc)) {
      return "VISA";
    }
    if (amex.test(cc)) {
      return "AMEX";
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
      return "MASTERCARD";
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      return "DISCOVER";
    }
    if (diners.test(cc)) {
      return "DINERS";
    }
    if (jcb.test(cc)) {
      return "JCB";
    }
    if (cup1.test(cc) || cup2.test(cc)) {
      return "CHINA_UNION_PAY";
    }
    if (cc.substring(0, 10) == "4242424242") {
      return "VISA";
    }
    return undefined;
  }

  function saveCard() {
    console.log("Saving Card...");
    let payment_method = {
      TransactionIdentifier: localStorage.getItem("tid") || "",
      first6: paymentCCNumber.substring(0, 6),
      last4: paymentCCNumber.substring(6, 10),
      expiration_month: paymentMonth,
      expiration_year: paymentYear,
      Source: {
        CardPan: paymentCCNumber,
        CardCvv: paymentCCV,
        CardExpiration: paymentYear + paymentMonth,
        CardholderName: paymentName,
      },
      OrderIdentifier: localStorage.getItem("oid") || "",
      card_holder_details: {
        card_holder_name: paymentName || "",
        address_line_1: paymentAddress1 || "",
        address_line_2: paymentAddress2 || "",
        address_city: paymentCity || "",
        address_zip: paymentZip || "",
        address_state: paymentState || "",
        address_country: paymentCountry || "",
        use_billing_address: true,
      },
      BrowserInfo: browserInfo,
    };

    api
      .post(
        "/crmcom/contacts/" +
          localStorage.getItem("contact_id") +
          "/payment_methods",
        payment_method
      )
      .then((res) => {
        console.log("--------- RESPONSE FROM TOKENIZATION ----------------");

        console.log(res);
        setSrcDoc(res?.data?.RiskManagement?.ThreeDSecure?.RedirectData || "");
        setShowFrame(true);
        navigate("/checkout");
        return;
      })
      .catch((err) => {
        console.log(err);
        //show toast error to user
        // setshowCardError(true);
        toast.error(
          "Card cannot be used. Please try another Debit or Credit card",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      });
  }

  function hidCardError() {
    setshowCardError(false);
  }

  function performCheckout(evt) {
    evt.preventDefault();
    // console.log('First 6 digits of CC number: ', paymentCCNumber.substring(0, 6));
    // console.log('Last 4 digits of CC number: ', paymentCCNumber.substring((paymentCCNumber.length -4), paymentCCNumber.length));
    // console.log('Credit card brand: ', creditCardType(paymentCCNumber));
    let payment_method = {
      name: "",
      payment_method_type: "CARD",
      is_primary: true,
      is_backup: true,
      notes: "",
      card: {
        // "card_number": paymentCCNumber,
        // "card_cvc": paymentCCV,
        id: "CARD",
        name: "Card",
        first6: paymentCCNumber.substring(0, 6),
        last4: paymentCCNumber.substring(6, 10),
        brand: creditCardType(paymentCCNumber),
        expiration_month: paymentMonth,
        expiration_year: paymentYear,
        country_of_issue: null,
        card_holder_details: {
          card_holder_name: paymentName,
          address_line_1: paymentAddress1,
          address_line_2: paymentAddress2,
          address_city: paymentCity,
          address_zip: paymentZip,
          address_state: paymentState,
          address_country: paymentCountry,
          use_billing_address: true,
        },
      },
      gateway_token: [
        {
          gateway: "FAC",
          token: "",
          integration_id: "48a278b4-5391-4ee3-8627-8026e83617a0",
        },
      ],
    };

    // console.log('Getting Payment Method: ');
    console.log(JSON.stringify(payment_method, null, 2));

    api
      .post(
        "/crmcom/contacts/" +
          localStorage.getItem("contact_id") +
          "/payment_methods",
        payment_method
      )
      .then((res) => {
        // setProducts(res.data)
        // console.log('Creating payment method result: ', res.data);
        let payment_method_id = res.data.id;
        console.log("payment_method_id: ", payment_method_id);
        api
          .get(
            "/crmcom/contacts/" +
              localStorage.getItem("contact_id") +
              "/payment_methods"
          )
          .then((res) => {
            // console.log('Fetch Payment Methods result: ', res.data);
            //setPaymentMethods(res.data.content);
            console.log(res.data.content);
            save_all_cards(res.data.content);
            /*
          let payment_method = res.data.content.find(o => o.id === payment_method_id);
          console.log('Selected payment method: ', payment_method);
          localStorage.setItem('payment_method', JSON.stringify(payment_method));
          */
          });
        //navigate('/checkout');
        // backToAP();
      });

    // backToAP();
  }

  const user_missing =
    localStorage.getItem("user") == "empty" ||
    localStorage.getItem("user") === null;
  const product_missing =
    localStorage.getItem("product") == "empty" ||
    localStorage.getItem("product") === null;

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const goHome = async (event) => {
    await delay(10000);
    window.location.replace("/");
  };

  const goPlans = async (event) => {
    await delay(10000);
    window.location.replace("/services");
  };

  const goHomeNow = async (event) => {
    window.location.replace("/");
  };

  const goPlansNow = async (event) => {
    window.location.replace("/services");
  };

  // api.get('/crmcom/contacts/' + JSON.parse(localStorage.getItem('user')).id + '/payment_methods').then(res => {
  //   console.log('Get contacts payment methods results: ', res.data);
  //   setPaymentMethods(res.data);
  // })

  //console.log("error")

  if (user_missing) {
    goHomeNow();

    return (
      <>
        <Navi />
        <div>
          <ProgressBar activeStep={activeStep} />
        </div>
        <Toast onClose={goHomeNow}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">No user</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Redirecting to signup in {counter} seconds.</Toast.Body>
        </Toast>
        {/* <Footy /> */}
      </>
    );
  } else {
    if (product_missing) {
      // console.log('Product is missing');

      goPlans();

      return (
        <>
          <Navi />
          <div>
            <ProgressBar activeStep={activeStep} />
          </div>
          <Toast onClose={goPlansNow}>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">No plan selected</strong>
              {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body>
              Redirecting to Select Plan in {counter} seconds.
            </Toast.Body>
          </Toast>
          {/* <Footy /> */}
        </>
      );
    } else {
      // console.log('The is a stored product');
      return (
        <>
          <Navi />
          <div>
            <ProgressBar activeStep={activeStep} />
          </div>

          

          <div className="page-checkout  col-lg-9 col-12 col-md-12 pb-2 m-0 pt-2 px-2 row">
            <div className="col-lg-6 col-12 col-sm-5 col-md-6 ">
            <div className="fs-1 fw-bold text-secondary">Checkout</div>
              <div className="section-service">
                
                <div className="section-payment-methods row ">
                  <div className="row mb-0 pb-0">
                    <div className="col-lg-8 p-0 col-12 col-md-8 mb-0">
                      <h6 className=" ps-3 mt-2">
                        <strong>Payment Method</strong>
                      </h6>
                    </div>
                  </div>

                  <div className="row text-truncate sw-pm">
                    {currentCard && currentCard.last4 != null && (
                      <>
                        <span className="m-0 col-auto ps-3  p-0">
                          {currentCard.brand || ""} ending in{" "}
                          {currentCard.last4}
                        </span>
                      </>
                    )}

                    <div className="col-lg-6 col-md-3 p-0 col-md-6 col-4 ps-3  text-start fs-6">
                      {currentCard && currentCard.last4 != null ? (
                        <Button
                          className="ps-0 pe-0 p-0"
                          variant="link"
                          onClick={setShow}
                        >
                          Change
                        </Button>
                      ) : (
                        <Button
                          className="ps-0 pe-0"
                          variant="link"
                          onClick={handleShowAddNew}
                        >
                          Add new payment method
                        </Button>
                      )}
                    </div>

                    {/* {paymentCards
                      .filter((item) => item.is_primary)
                      .map((item) => (
                        <div key={item.id}>
                          <p>
                            {item.card.brand} ending in {item.card.last4}
                          </p>
                        </div>
                      ))} */}
                  </div>

                  <Modal show={show} onHide={handleClose} dialogClassName="lg">
                    <Modal.Header closeButton>
                      <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <h4 className="iki-sm-txt fs-6">
                          Select or add a payment method
                        </h4>
                      </div>

                      <div className="m-0 iki-btn-group">
                        <div>
                          <Button variant="link" onClick={handleShowAddNew}>
                            + Add a credit or debit card
                          </Button>

                          {/* <Button
                            className="btn-sm btn-light float-end"
                            onClick={goBack}
                          >
                            <i className="mdi mdi-arrow-left"></i> Back
                          </Button> */}
                        </div>
                      </div>

                      <div className="page-loading">
                        <Row className="text-center m-0 p-0">
                          <Col>
                            <div
                              className={
                                loaded == false
                                  ? "iki-spinner spinner-border"
                                  : ""
                              }
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      {showCardUnselected ? (
                        <Toast
                          className="bg-info bg-gradient"
                          onClose={hideNoCard}
                        >
                          <Toast.Header>
                            <h6 className="me-auto">No card selected</h6>
                          </Toast.Header>
                        </Toast>
                      ) : (
                        ""
                      )}

                      <ListGroup className="iki-list-group">
                        {paymentCards.map((payment_method) => (
                          <ListGroupItem
                            key={payment_method.id}
                            className={
                              active == payment_method.id ? "item-selected" : ""
                            }
                            onClick={() => setSelectedItem(payment_method)}
                          >
                            <div className="iki-contact-cards">
                              <div>
                                {active == payment_method.id ? (
                                  <i className="mdi mdi-checkbox-blank"></i>
                                ) : (
                                  <i className="mdi mdi-checkbox-blank-outline iki-light"></i>
                                )}
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {payment_method.card.brand}&nbsp;&nbsp;
                                </span>
                                <span> * * * * </span>
                                <span>{payment_method.card.last4}</span>&nbsp;
                                <span></span>
                              </div>
                              <div>
                                <span>
                                  {
                                    payment_method.card.card_holder_details
                                      .card_holder_name
                                  }
                                </span>
                                &nbsp;
                              </div>
                            </div>
                            {payment_method.is_primary == true && (
                              <div className="sw-primary-card">PRIMARY</div>
                            )}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Modal.Body>
                    {/* <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                        </Modal.Footer> */}
                  </Modal>

                  <Modal
                    show={showAddNewCard}
                    onHide={handleCloseAddNew}
                    dialogClassName="lg"
                  >
                    <Modal.Header closeButton className="pb-0">
                      <Modal.Title>Add Card</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="mt-0 pt-0">
                      {showCardError ? (
                        <Toast
                          style={{
                            backgroundColor: "#f00",
                            color: "#fff",
                            borderRadius: 0,
                          }}
                          onClose={hidCardError}
                          className="mt-2"
                        >
                          <Toast.Header>
                            <p>
                              Error adding card. Please try another debit or
                              credit card.
                            </p>
                          </Toast.Header>
                        </Toast>
                      ) : (
                        ""
                      )}

                      <Card className="border-0">
                        {/* <Card.Header>
                          <strong>Card Details</strong>
                        </Card.Header> */}
                        {/* <strong>Card Details</strong> */}
                        <Form onSubmit={performCheckout}>
                          <Card.Body>
                            <Row>
                              <Col className="col-sm-12">
                                <div className="form-group">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    value={paymentName}
                                    onChange={changePaymentName}
                                    required
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-sm-12">
                                <div className="form-group">
                                  <Form.Label>Credit Card Number</Form.Label>
                                  <div className="input-group">
                                    <Form.Control
                                      type="text"
                                      placeholder="0000 0000 0000 0000"
                                      maxlenght="10"
                                      value={paymentCCNumber}
                                      onChange={changePaymentCCNumber}
                                      required
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        <i className="mdi mdi-credit-card"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="form-group col-sm-4">
                                <Form.Label>Month</Form.Label>
                                <Form.Select
                                  value={paymentMonth}
                                  onChange={changePaymentMonth}
                                  required
                                >
                                  <option value="01">1</option>
                                  <option value="02">2</option>
                                  <option value="03">3</option>
                                  <option value="04">4</option>
                                  <option value="05">5</option>
                                  <option value="06">6</option>
                                  <option value="07">7</option>
                                  <option value="08">8</option>
                                  <option value="09">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                </Form.Select>
                              </Col>
                              <Col className="form-group col-sm-4">
                                <Form.Label>Year</Form.Label>
                                <Form.Select
                                  value={paymentYear}
                                  onChange={changePaymentYear}
                                  required
                                >
                                  {[...Array(16)].map((_, i) => {
                                    const year = CurrentYear + i;
                                    const lastTwoDigits =
                                      String(year).slice(-2);
                                    return (
                                      <option
                                        key={lastTwoDigits}
                                        value={lastTwoDigits}
                                      >
                                        {year}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                              <Col className="form-group col-sm-4">
                                <Form.Label>CVV/CVC</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="123"
                                  value={paymentCCV}
                                  onChange={changePaymentCCV}
                                  required
                                />
                                <br />
                              </Col>
                            </Row>

                            <Row>
                              <Card.Header>
                                <strong>Billing Address</strong>
                              </Card.Header>
                              <br />
                              <Col className="form-group">
                                <br />
                                {/* <Form.Label>Address</Form.Label> */}
                                <Form.Control
                                  type="text"
                                  placeholder="Street"
                                  value={paymentAddress1}
                                  onChange={changePaymentAddress1}
                                  required
                                />
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col className="form-group">
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Address Line 2"
                                  value={paymentAddress2}
                                  onChange={changePaymentAddress2}
                                  required
                                />
                              </Col>
                            </Row> */}
                            <Row>
                              {/* <Col className="form-group col-sm-4">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="State"
                                  value={paymentState}
                                  onChange={changePaymentState}
                                  required
                                />
                              </Col> */}
                              <Col className="form-group col-sm-12">
                                {/* <Form.Label>City</Form.Label> */}
                                <Form.Control
                                  type="text"
                                  placeholder="City"
                                  value={paymentCity}
                                  onChange={changePaymentCity}
                                  required
                                />
                              </Col>
                              {/* <Col className="form-group col-sm-4">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Zip"
                                  value={paymentZip}
                                  onChange={changePaymentZip}
                                  required
                                />
                              </Col> */}
                            </Row>
                            <Row>
                              <Col className="form-group">
                                {/* <Form.Label>Country</Form.Label> */}
                                <Select
                                  options={countries}
                                  onChange={changePaymentCountry}
                                  required
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer className="border-0 bg-transparent">
                            <div className="iki-btn-group m-0">
                              <div>
                                <Button
                                  onClick={handleShow}
                                  className="btn-md btn-light float-end"
                                >
                                  Cancel
                                </Button>
                              </div>
                              <div>
                                <Button
                                  className="btn-md btn-primary float-end"
                                  onClick={saveCard}
                                >
                                  Save Card
                                </Button>
                              </div>
                            </div>
                          </Card.Footer>
                        </Form>
                      </Card>
                    </Modal.Body>
                    {/* <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                        </Modal.Footer> */}
                  </Modal>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12 col-sm-7 sw-checkout-r p-3 col-md-6">
              <div className="sw-ch-pr">
                <Row className="text-left">
                  <h6 className="col-12">
                    <strong>Complete Purchase:</strong>{" "}
                  </h6>
                  <p className="m-0 col-10">
                    {JSON.parse(localStorage.getItem("product")).name}
                    &nbsp; &nbsp;
                  </p>
                  <p className="m-0 col-2">
                    <strong>
                      $
                      {
                        JSON.parse(localStorage.getItem("product")).pricing
                          .price
                      }
                    </strong>
                  </p>
                </Row>
              </div>

              <div className="mt-1 mb-0 text-center p-2 small sw-refund-alert">
                <p className="mb-1">
                  Please note, SwitchTT has a <strong>no refund policy</strong>{" "}
                  for the services we offer. By clicking, "Use this payment
                  method", you are agreeing to the terms of our{" "}
                  <a href="/refund-policy">Refund Policy</a>
                </p>
              </div>

              <div className="d-flex  justify-content-end">
                <Button
                  className="btn-md btn-primary"
                  disabled={hidePayButton}
                  onClick={() => {
                    doCheckout();
                  }}
                >
                  Confirm and Pay{" "}
                  <i className="mdi mdi-arrow-right"></i>
                  {/* <i className="mdi mdi-arrow-right right"></i> */}
                </Button>
              </div>

            </div>

            
          </div>

          <Modal
            show={showFrame}
            onHide={handleCloseFrame}
            height="100vh"
            // style={{ height: height }}

            dialogClassName="modal-fullscreen"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                className="fac-iframe"
                ref={iframeRef}
                srcDoc={srcDoc}
                onLoad={() => {
                  setLoading(false);
                }}
                style={{ border: "none" }}
                title="FAC"
                width="100%"
                height="100%"
              />
              {loading && (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Spinner /> */}
                  </div>
                  <div
                    className="page-loading iki-spinner spinner-border"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>

          {/* <Footy /> */}
        </>
      );
    }
  }
};

export default Checkout;
