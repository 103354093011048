import {React, Component, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";


import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';
import Toast from 'react-bootstrap/Toast';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Navi from './nav';

import { useEffect } from 'react';


import { useParams } from 'react-router-dom';

import axios from 'axios';

import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const backToAP = () => {
    window.location.open("https://splash.ironwifi.com/r-qm4uoduo--venue001/?_gl=1*9g5f3n*_ga*MTU4NjAyMzgyMi4xNjU3OTA1OTQw*_ga_HNYWQGR6BS*MTY2MTc3NjE4MC4zMi4xLjE2NjE3NzYyMTQuMjYuMC4w", "_blank"); //&username=" + JSON.parse(localStorage.getItem('user')).email);
}

const gotoTelebreeze = () => {
    // window.location.replace("https://my.telebreeze.com/user/login");
    window.open("https://my.telebreeze.com/user/login", "_blank");
}

function Confirmation() {

    useEffect(() => {
        console.log('Selected product: ', JSON.parse(localStorage.getItem('product')));
        console.log('Selected product type id: ', JSON.parse(localStorage.getItem('product')).type.id);
        console.log('Selected product type name: ', JSON.parse(localStorage.getItem('product')).type.name);
    }, [])

    return (
        <>
            <Navi />
            <Row>
            <Col>
                <h6>Congratulations. You sucessfully purchased the "{JSON.parse(localStorage.getItem('product')).name}" plan.</h6><br/><br/>
                <p> Order: <span> {localStorage.getItem('order_id')} </span> </p><br/><br/>
                <Button onClick={backToAP}>Connect to Internet</Button>
                &nbsp; &nbsp; &nbsp; &nbsp;
                { (JSON.parse(localStorage.getItem('product')).type.id == '0b74aa86-ece7-4cc3-82b8-2cf657b5e8dc' || JSON.parse(localStorage.getItem('product')).type.id == '3737190a-f3ab-40ae-8823-0ae2a8d8c5c1') ? <Button onClick={gotoTelebreeze}>Login to IPTV</Button> : '' }
                {/* <Button onClick={gotoTelebreeze}>Login to IP TV</Button> */}
                

            </Col>
        </Row>
        </>
        
    )

}

export default Confirmation;
