import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function OurServices() {
  return (
    <>
      <Navi />

      <div className="page-sw   col-lg-8 col-sm-12 col-md-12">
        <>
          <h1 className="fs-2 fw-bold mb-3 ">
            Our Services
          </h1>
          <h5>Internet Packages</h5>
          <p>
            At SwitchTT, we believe that high-quality internet access should be
            flexible and affordable. That's why we offer a range of internet
            packages to suit every need and budget. Whether you're looking for
            short-term access or a monthly plan, we've got you covered. Our
            packages start from 1-day access, perfect for visitors or temporary
            users, and extend up to 30-day access for ongoing, reliable
            connectivity. Each of our packages offers a strong and stable
            connection, ensuring you can browse, stream, and communicate without
            interruption.
          </p>

          <h5>IPTV Packages</h5>
          <p>
            SwitchTT is proud to offer IPTV packages as part of our suite of
            services. Our IPTV service provides a wide range of channels and
            programming, all delivered through our reliable internet connection.
            Every month, subscribers can enjoy a wealth of content from local
            and international sources. Whether you're a sports fan, a movie
            buff, or looking for kids' programming, our IPTV service has
            something for everyone. Enjoy crystal-clear picture quality and a
            vast selection of options with our monthly IPTV packages.
          </p>

          <h5>Combo Packages</h5>
          <p>
            For the ultimate convenience and value, SwitchTT offers Combo
            Packages that bundle together our top-quality Internet and IPTV
            services. These packages are designed to give you the best of both
            worlds, with the freedom and flexibility of our internet services
            and the rich content of our IPTV offering. Whether you're settling
            down for a movie night, streaming your favourite music, or keeping
            up with the latest news, our Combo Packages have you covered.
            Explore our different tiers and find the package that's right for
            you!
          </p>

          <p>
            At SwitchTT, we're committed to providing the connectivity and
            content you need, right at your fingertips. Contact us today to find
            out more about our internet, IPTV and combo packages, and discover
            the SwitchTT difference.
          </p>
        </>
      </div>
    </>
  );
}

export default OurServices;
