import React from "react";
import ReactDOM from "react-dom/client";
import { Link, Navigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import axios from "axios";
import Cookies from "js-cookie";

const logo = require("../logo.png");

import "bootstrap/dist/css/bootstrap.min.css";

function Navi() {
  const [userLoggedIn, setLoggedIn] = useState(false);
  const [hasActiveProduct, setHasActiveProduct] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const screenHeight = window.innerHeight || "200px";
  const screenWidth = window.innerWidth || "200px";

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const logIn = async () => {
    //let logoutConfirmed = confirm('Do you want to logout?');
    const isLoggedIn =
      localStorage.getItem("user") == "empty" ||
      localStorage.getItem("user") === null
        ? false
        : true;
    if (isLoggedIn) {
    }
  };

  const logOut = async () => {
    let logoutConfirmed = confirm("Do you want to logout?");
    if (logoutConfirmed) {
      api
        .post("/users/logout", {})
        .then((res) => {
          console.log("Logout response: ", res.data);
          console.log("host: ", window.location.hostname);
          Cookies.remove("switchttbuild.sytes.net");
          Cookies.remove(window.location.hostname);
          localStorage.clear();
          window.location.replace("https://" + window.location.host);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    // setUser(
    //   (!localStorage.getItem('user') || localStorage.getItem('user') == 'empty' || localStorage.getItem('user') === null) ?
    //     ""
    //     :
    //     JSON.parse(localStorage.getItem('user')
    // );
    // console.log('Stored user: ' + localStorage.getItem('bob'));
    // if(!localStorage.getItem('user') || localStorage.getItem('user') == 'empty' || localStorage.getItem('user') === null) {
    //   setUser({first_name: '', last_name: ''})
    // } else {
    //   setUser(JSON.parse(localStorage.getItem('user')));
    // }
    // console.log("____________________________");
    // console.log('user: ', user);
    // console.log("____________________________");

    // console.log(userLoggedIn)

    if (localStorage.hasOwnProperty("user")) {
      // console.log("user logged in '" + localStorage.getItem('user') + "'");
      if (
        localStorage.getItem("user") &&
        localStorage.getItem("user") === "empty"
      ) {
        // console.log('Stored user IS empty')
        setLoggedIn(false);
      } else {
        // console.log('Stored user is NOT empty')
        setLoggedIn(true);
        setUser(JSON.parse(localStorage.getItem("user")));
      }
    } else {
      setLoggedIn(false);
      // console.log("user not logged in")
    }
  }, []);

  // active product
  useEffect(() => {
    if (localStorage.hasOwnProperty("active_product")) {
      if (
        localStorage.getItem("active_product") &&
        localStorage.getItem("active_product") === "undefined"
      ) {
        setHasActiveProduct(true);
      } else {
        setHasActiveProduct(false);
      }
    }
  }, []);

  return (
    <>
      <header className="iki-header">
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand xs="4" className="" href="/">
              <img src={logo} alt="logo" className="sw-logo" />
            </Navbar.Brand>
            {userLoggedIn === false ? (
              <></>
            ) : (
              <>
                {/* <Navbar.Toggle xs="8" aria-controls="basic-navbar-nav" /> */}
                <NavDropdown className="iki-my-account"
                  title={
                    <span >
                      <i className="mdi mdi-account small fs-6"></i> My Account
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <div
                    className="iki-account"
                    style={{
                      height: screenHeight,
                      width: screenWidth,
                    }}
                  >
                    <NavDropdown.Item className=" fs-4 " href="/history">
                      <i className="mdi mdi-history"></i> Order History
                    </NavDropdown.Item>
                    <NavDropdown.Item className=" fs-4 " href="/account">
                      <i className="mdi mdi-settings"></i> Manage Account
                    </NavDropdown.Item>
                    <NavDropdown.Item className=" fs-4 " href="/reset">
                      <i className="mdi mdi-key"></i> Reset Password
                    </NavDropdown.Item>
                    <NavDropdown.Item className=" fs-4 " onClick={logOut}>
                      <i className="mdi mdi-exit-to-app"></i> Log out
                    </NavDropdown.Item>
                    <div className="row mt-3 pt-2 border-top">
                      <div className="col-6 ">
                        <NavDropdown.Item className=" fs-4 " href="/">
                          <i className="mdi mdi-home"></i> Home
                        </NavDropdown.Item>
                      </div>
                      <div className="col-6 ">
                        <NavDropdown.Item className=" fs-4 " href="/services">
                          <i className="mdi mdi-plus"></i> Add Service
                        </NavDropdown.Item>
                      </div>
                      <div className="col-12 text-center mt-4">
                        <img src={logo} alt="logo" className="sw-logo" />
                      </div>
                    </div>
                  </div>
                </NavDropdown>
                <Navbar id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <div className="iki-header-nav">
                      <div className="iki-header-nav-left">
                        {userLoggedIn === false ? (
                          <></>
                        ) : (
                          <>
                          <Nav.Link className="iki-home" href="/">
                            <i className="mdi mdi-home"></i> Home
                          </Nav.Link>
               
                        </>
                        )}
                        {userLoggedIn === false ? (
                          <></>
                        ) : (
                          <Nav.Link className="iki-home" href="/services">
                            <i className="mdi mdi-plus-circle" /> Add Service
                          </Nav.Link>
                        )}
                        {userLoggedIn === true &&
                        !!localStorage.getItem("product") ? (
                          <Nav.Link href="/cart" className="iki-cart iki-home ">
                            <i className="mdi mdi-cart"></i> Cart
                          </Nav.Link>
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* <div className="iki-header-nav-right">
                        {userLoggedIn ? (
                          <>
                            <NavDropdown
                              title="My Account"
                              id="basic-nav-dropdown"
                              className="iki-logout"
                            >
                              <NavDropdown.Item href="/history">
                                <i className="mdi mdi-history"></i> History
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/account">
                                <i className="mdi mdi-account-circle"></i>{" "}
                                Manage
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/reset">
                                <i className="mdi mdi-key"></i> Reset Password
                              </NavDropdown.Item>
                              <NavDropdown.Item onClick={logOut}>
                                <i className="mdi mdi-logout"></i> Log out
                              </NavDropdown.Item>
                            </NavDropdown>
                          </>
                        ) : (
                          <></>
                        )}
                      </div> */}
                    </div>
                  </Nav>
                </Navbar>
              </>
            )}
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default Navi;
