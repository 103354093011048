import React from "react";
import ReactDOM from "react-dom/client";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Footy from "../src/component/footer";
import SwFooter from "../src/component/footer_sw";

// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
// import { useNavigate } from "react-router-dom";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import SocialLogin from "react-social-login";
import History from "./component/history";
import Privacy from "./component/privacy-policy";
import RefundPolicy from "./component/refund-policy";
import DeliveryPolicy from "./component/delivery-policy";
import TermsOfUse from "./component/terms";
import SecurityPolicy from './component/security-policy';

import Company from "./component/about";
import Contact from "./component/contact";
import OurServices from "./component/our-services";


import Checkout from "./component/checkout";
import Services from "./component/services";
import Estimate from "./component/estimate";
import Payment from "./component/payment";
import AuthorizationResult from "./component/authorization_result";
import TokenizationResult from "./component/tokenization_result";
import TokenizationResultAccount from "./component/tokenization_result_account";
import Free from "./component/free";
// import Telebreeze from './component/telebreze';
import Landing from "./component/landing";
import Cart from "./component/cart";
import Confirmation from "./component/confirmation";
import Account from "./component/account";
import Reset from "./component/reset";
import Password from "./component/password";
import Forgot from "./component/forgot";
import Test from "./component/test";
import Navi from "./component/nav";
import logo from "./logo.png";

import "./index.css";
import "./App.css";
import "./checkout.css";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

const logOut = async () => {
  let logoutConfirmed = confirm("Do you want to logout?");
  if (logoutConfirmed) {
    console.log("Logging out");
    localStorage.clear();
    // await delay(2000);
    // navigate('/services');
    window.location.replace("/");
  }
};

const logIn = async () => {
  //let logoutConfirmed = confirm('Do you want to logout?');
  const isLoggedIn =
    localStorage.getItem("user") == "empty" ||
    localStorage.getItem("user") === null
      ? 0
      : 1;

  if (isLoggedIn) {
  }
};

// const navigate = useNavigate();

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

root.render(
  // <GoogleOAuthProvider clientId="791796628970-7c4jtm8fo54o6ftgb7qdag12iden3ta4.apps.googleusercontent.com">
  <React.StrictMode>
    <BrowserRouter>
      {/* <Navi /> */}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Container className="app-container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/services" element={<Services />} />
              <Route path="/estimate" element={<Estimate />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/delivery-policy" element={<DeliveryPolicy />} />
              <Route path="/security-policy" element={<SecurityPolicy />} />
              <Route path="/terms" element={<TermsOfUse />} />
              <Route path="/about" element={<Company />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/our-services" element={<OurServices />} />

              <Route path="/authresult" element={<AuthorizationResult />} />
              <Route path="/authresult/:session_id" element={<AuthorizationResult />}/>

              <Route
                path="/tokenizationresult"
                element={<TokenizationResult />}
              />
              <Route
                path="/tokenizationresult/:session_id"
                element={<TokenizationResult />}
              />
              <Route
                path="/tokenizationresultaccount"
                element={<TokenizationResultAccount />}
              />
              <Route
                path="/tokenizationresultaccount/:session_id"
                element={<TokenizationResultAccount />}
              />

              <Route path="/history" element={<History />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/free" element={<Free />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route path="/account" element={<Account />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/password" element={<Password />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/test" element={<Test />} />
            </Routes>
          </div>
        </div>
      </Container>
    </BrowserRouter>
    <div className="iki-sw-footer">
      <div>
        {<Footy />}
        {<SwFooter />}
      </div>
    </div>
  </React.StrictMode>
  // </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
