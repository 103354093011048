import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import { Form, Button } from "react-bootstrap";

import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Contact() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <Navi />

      <div className="page-sw   col-lg-8 col-sm-12 col-md-12">
        <>
          <h1 className="fs-2 fw-bold mb-3">
            Contact Us
          </h1>
          <div className="d-flex row">
            <div className="col-12 col-md-6 mb-4">
              At SwitchTT, we're always ready to assist you with any queries or
              issues you might have. To help us better understand and resolve
              your concerns, please fill out the form below and a member of our
              customer service team will respond as soon as possible.
            </div>
            <div className="col-md-6 flex-grow-1 sw-contact-form p-3">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                <Form.Label  className="mb-1 mt-2">
                    Full Name<span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your full name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your full name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formEmail">
                <Form.Label  className="mb-1 mt-2">
                    Email<span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter your email"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formMessage">
                  <Form.Label  className="mb-1 mt-2">
                    Message<span>*</span>
                  </Form.Label>
                  <Form.Control className="" required as="textarea" rows={3} />
                  <Form.Control.Feedback type="invalid">
                    Please provide a message.
                  </Form.Control.Feedback>
                </Form.Group>

                <Button className="mt-2 btn-black" variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default Contact;
