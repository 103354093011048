import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import Footy from "./footer";

import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import Toast from "react-bootstrap/Toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

import { useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import "../App.css";
import "../services.css";
import "../history.css";

import "bootstrap/dist/css/bootstrap.min.css";
axios.defaults.withCredentials = true;

const api = axios.create({
  // https://api.switchtt.a2hosted.com
  baseURL: process.env.REACT_APP_API_URL,
  // 'https://switchtt.sytes.net/api'
  // baseURL: 'https://localhost:4001/api'
});

const crm = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/crmcom",
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
  },
});

function Services() {
  const [counter, setCounter] = useState(10);

  const [loaded, setLoaded] = useState(false);

  const params = useParams();

  const [products, setProducts] = useState([]);
  const [active, setActive] = useState(-1);
  const [activeProductSku, setActiveProductSku] = useState("");

  const [productSelected, setProductSelected] = useState(false);
  const [selectError, setSelectError] = useState("");
  const [errorSelecting, setErrorSelecting] = useState(false);
  const [showProductUnselected, setShowProductUnselected] = useState(false);
  const [plans, setPlans] = useState({
    active_services: [],
    inactive_services: [],
  });

  const [internet_plans, setInternetPlans] = useState([]);
  const [tv_plans, setTVPlans] = useState([]);
  const [combo_plans, setComboPlans] = useState([]);
  const [one_time_plans, setOneTimePlans] = useState([]);

  const [product_activated, setActiveProduct] = useState("");

  const [serviceStatus, setServiceStatus] = useState({});

  const [dataActive, setDataActive] = useState(false);

  const [tvActive, setTvActive] = useState(false);

  const [cartDisabled, setCartDisabled] = useState(true);

  const navigate = useNavigate();

  const isActive = (service) => {
    // console.log('Active Plans: ', plans.active_services);
    let active_plans = plans.active_services;
    // console.log('Service: ', service);
    // console.log('Active Plans first product_id: ', plans.active_services[0].product_id);
    // console.log('Service id: ', service.id);
    let planActive = active_plans.find((o) => o.product_id === service.id);
    // console.log('planActive: ', planActive);
    let planActiveDefined = typeof planActive;
    // console.log('planActiveDefined: ' + planActiveDefined);
    if (planActiveDefined === "undefined") {
      // console.log('planActive IS undefined');
      return false;
    } else {
      // console.log('planAcive is NOT undefined');
      return true;
    }
  };

  const hideNoPlan = () => {
    setShowProductUnselected(false);
  };

  const hideErrorSelecting = () => {
    setErrorSelecting("");
  };

  const goBack = () => {
    navigate("/");
  };

  const gotoCart = () => {
    if (productSelected) {
      navigate("/cart");
    } else {
      setShowProductUnselected(true);
    }
  };

  const planExists = (product_type) => {
    const integration_ids = ["a71200aa-0e8e-4c8a-ab05-ae8a037c0649", ""];
    if (product_type == current_product_type) {
      return true;
    }
    //bundled services
  };

  const setSelectedItem = (product) => {
    console.log(activeProductSku + " == " + product.sku);
    //check if a similar plan (Internet/IPTV) alread exists for user
    // if(!planExists(product.type.id ? product.type.id : "")){
    //   setActive(product.sku)
    //   localStorage.setItem('product', JSON.stringify(product));
    // }
    if (activeProductSku != product.sku) {
      setActive(product.sku);
      setActiveProductSku(product.sku);
      setProductSelected(true);
      setCartDisabled(false);
      setShowProductUnselected(false);
      setErrorSelecting("");
      localStorage.setItem("product", JSON.stringify(product));
    } else {
      setShowProductUnselected(true);

      setActive("");
      setActiveProductSku("");
      setProductSelected(false);
      setCartDisabled(true);
      setShowProductUnselected(true);
      setErrorSelecting("");
      localStorage.removeItem("product");
    }
  };

  const gotoFree = () => {
    navigate("/free");
  };

  const goHome = async (event) => {
    await delay(10000);
    window.location.replace("/");
  };

  const goHomeNow = async (event) => {
    window.location.replace("/");
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  let location = useLocation();

  const user_missing =
    localStorage.getItem("user") == "empty" ||
    localStorage.getItem("user") === null;

  function selectPlan(plan) {
    console.log("TRANSCEND service", serviceStatus);
    console.log("TRANSCEND plan", plan);

    if (serviceStatus.wifi && plan.type.name === "Internet Packages") {
      setErrorSelecting(
        `Your active plan (${product_activated}) already includes Internet. You can add another Internet plan when it expires.`
      );
      setCartDisabled(true);
    } else {
      if (serviceStatus.iptv && plan.type.name == "IPTV Packages") {
        setErrorSelecting(
          `Your active plan (${product_activated}) already includes IPTV.  You can add another plan or bundle that includes IPTV when the current one expires.`
        );
        setCartDisabled(true);
      } else {
        if (serviceStatus.iptv_wifi && plan.type.name == "Internet + IPTV") {
          setErrorSelecting(
            `Your active plan (${product_activated}) already includes Internet and IPTV.  You can add another plan or bundle that includes Internet and IPTV access when the current one expires.`
          );
          setCartDisabled(true);
        } else {
          setSelectedItem(plan);
        }
      }
    }
  }

  useEffect(() => {
    //get the currecnt active product
    // const current_product = (localStorage.getItem('active_product_name') && localStorage.getItem('active_product_name') != "")  ? localStorage.getItem('active_product_name') : "";

    setActiveProduct(
      localStorage.getItem("active_product_name") &&
        localStorage.getItem("active_product_name") != ""
        ? localStorage.getItem("active_product_name")
        : ""
    );
    let service_status = JSON.parse(localStorage.getItem("service_status"));
    setServiceStatus(service_status);

    // console.log('TRANSCEND service status: ', serviceStatus);

    if (!user_missing) {
      setActiveProduct(
        localStorage.getItem("active_product_name") &&
          localStorage.getItem("active_product_name") != ""
          ? localStorage.getItem("active_product_name")
          : ""
      );

      crm
        .get("/refresh")
        .then((res) => {
          setServiceStatus(res.data.service_status);
          localStorage.setItem(
            "service_status",
            JSON.stringify(res.data.service_status)
          );
        })
        .catch((err) => {
          console.log("TRANSCEND user refresh err: ", err);
        });

      let service_status = JSON.parse(localStorage.getItem("service_status"));
      setServiceStatus(service_status);

      api
        .get(
          "/crmcom/contacts/" +
            JSON.parse(localStorage.getItem("user")).id +
            "/services"
        )
        .then((res) => {
          // console.log('Transcend Fetch plans result: ', res.data);
          let current_active_plans = res.data.active_services;
          setLoaded(true);
          setPlans(res.data);

          api
            .get("/products")
            .then((res) => {
              // console.log('Fetch services result: ', res.data);
              setLoaded(true);
              let inactive_services = [];
              let services = res.data;
              for (const service of services) {
                if (
                  typeof current_active_plans.find(
                    (o) => o.product_id === service.id
                  ) === "undefined"
                ) {
                  service.is_selectable = true;
                  inactive_services.push(service);
                } else {
                  service.is_selectable = false;
                  if (
                    service.type.id == "0b74aa86-ece7-4cc3-82b8-2cf657b5e8dc" ||
                    service.type.id == "3737190a-f3ab-40ae-8823-0ae2a8d8c5c1"
                  ) {
                    //  console.log('"' + service.name + '" is of type ' + service.type.name );
                    //  console.log('Setting tvActive to true');
                    setTvActive(true);
                  } else {
                    //  console.log('"' + service.name + '" is of type ' + service.type.name );
                    //  console.log('Setting dataActive to true');
                    setDataActive(true);
                  }
                  inactive_services.push(service);
                }
                console.log(inactive_services);
              }

              console.log("TRANSCEND fetch services: ", inactive_services);
              let internet_services = [];
              let tv_services = [];
              let combo_services = [];
              let one_time_services = [];
              inactive_services.find(function (plan, index) {
                if (plan.type.name == "Internet Packages") {
                  internet_services.push(plan);
                  setInternetPlans(internet_services);
                }
                if (plan.type.name == "IPTV Packages") {
                  tv_services.push(plan);
                  setTVPlans(tv_services);
                }
                if (plan.type.name == "Internet + IPTV") {
                  combo_services.push(plan);
                  setComboPlans(combo_services);
                }
                if (plan.type.name == "Test Internet One Time service") {
                  one_time_services.push(plan);
                  console.log(one_time_services);
                  setOneTimePlans(one_time_services);
                }
              });

              setProducts(inactive_services);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  if (!user_missing) {
    // console.log('Existing user: ', JSON.parse(localStorage.getItem('user')));
    if (!loaded) {
      return (
        <>
          <Navi />
          <div className="page-services-loading col-lg-6">
          {product_activated ? (
                <div className="iki-current-active fs-6">
                  Your current plan: <strong>{product_activated}</strong>
                </div>
              ) : (
                ""
              )}
            <Row className="text-center">
              <Col>
                <div
                  className="page-loading iki-spinner spinner-border"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
                <br />
              </Col>
            </Row>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Navi />

      
          <div className="page-services col-lg-6 col-sm-12">
          {product_activated ? (
                <div className="iki-current-active fs-6">
                  Current plan(s): <strong>{product_activated}</strong>
                </div>
              ) : (
                ""
              )}
            <div>
              {/*<h4 className="iki-hello">Hello { JSON.parse(localStorage.getItem('user')).first_name }, please select a plan below:</h4>*/}
              
              
              {/* <div>
                <h5 className="iki-hello text-center fs-6">
                  Select an Internet / IPTV plan below
                </h5>
              </div> */}

              <p className="iki-sm-txt mb-2">All costs are in TT Dollars.</p>


              

              {showProductUnselected ? (
                <Toast
                  className="mb-2 border-0 border-radius-0  iki-error-toast bg-info bg-gradient"
                  onClose={hideNoPlan}
                >
                  <Toast.Header>
                    <h6 className="me-auto">No plan selected</h6>
                  </Toast.Header>
                </Toast>
              ) : (
                ""
              )}

              {!(errorSelecting == "") ? (
                <Toast
                  className="mb-2 border-0 border-radius-0 iki-error-toast bg-info bg-gradient"
                  onClose={hideErrorSelecting}
                >
                  <Toast.Header className="border-0 border-radius-0">
                    <h6 className="me-auto">{errorSelecting}</h6>
                  </Toast.Header>
                </Toast>
              ) : (
                ""
              )}

              <ListGroup className="iki-list-group bg">
                <h6 className="transcend-category">
                  Combination IPTV + Internet Packages
                </h6>
                {combo_plans.map((product) => (
                  <ListGroupItem
                    key={product.sku}
                    className={
                      
                      (active == product.sku ? "item-selected " : " sw-item") +
                      (!product.is_selectable ? " service-active " : " sw-item")
                    }
                    //onClick={()=>setActive(product.sku)}
                    onClick={() =>
                      product.is_selectable
                        ? selectPlan(product)
                        : alert("This plan is already active")
                    }
                  >
                    <div>
                      {active == product.sku ? (
                        <i className="mdi mdi-checkbox-blank"></i>
                      ) : (
                        <i className="mdi mdi-checkbox-blank-outline iki-light"></i>
                      )}
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <span>{product.name}</span>

                      <h5 className="float-end">${product.pricing.price}</h5>
                    </div>
                  </ListGroupItem>
                ))}

                <h6 className="transcend-category">Internet Packages</h6>
                {internet_plans.map((product) => (
                  <ListGroupItem
                    key={product.sku}
                    className={
                      (active == product.sku ? "item-selected" : "  sw-item") +
                      (!product.is_selectable ? " service-active" : "  sw-item")
                    }
                    //onClick={()=>setActive(product.sku)}
                    onClick={() =>
                      product.is_selectable
                        ? selectPlan(product)
                        : alert("This plan is already active")
                    }
                  >
                    <div>
                      {active == product.sku ? (
                        <i className="mdi mdi-checkbox-blank"></i>
                      ) : (
                        <i className="mdi mdi-checkbox-blank-outline iki-light"></i>
                      )}
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <span>{product.name}</span>

                      <h5 className="float-end">${product.pricing.price}</h5>
                    </div>
                  </ListGroupItem>
                ))}

                <h6 className="transcend-category">IPTV Packages</h6>
                {tv_plans.map((product) => (
                  <ListGroupItem
                    key={product.sku}
                    className={
                      (active == product.sku ? "item-selected" : "  sw-item") +
                      (!product.is_selectable ? " primary-card" : "  sw-item")
                    }
                    //onClick={()=>setActive(product.sku)}
                    onClick={() =>
                      product.is_selectable
                        ? selectPlan(product)
                        : alert("This plan is already active")
                    }
                  >
                    <div>
                      {active == product.sku ? (
                        <i className="mdi mdi-checkbox-blank"></i>
                      ) : (
                        <i className="mdi mdi-checkbox-blank-outline iki-light"></i>
                      )}
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <span>{product.name}</span>

                      <h5 className="float-end">${product.pricing.price}</h5>
                    </div>
                  </ListGroupItem>
                ))}

                <h6 className="transcend-category">Test One Time Services Plans</h6>
                {one_time_plans.map((product) => (
                  <ListGroupItem
                    key={product.sku}
                    className={
                      (active == product.sku ? "item-selected" : "  sw-item") +
                      (!product.is_selectable ? " primary-card" : "  sw-item")
                    }
                    //onClick={()=>setActive(product.sku)}
                    onClick={() =>
                      product.is_selectable
                        ? selectPlan(product)
                        : alert("This plan is already active")
                    }
                  >
                    <div>
                      {active == product.sku ? (
                        <i className="mdi mdi-checkbox-blank"></i>
                      ) : (
                        <i className="mdi mdi-checkbox-blank-outline iki-light"></i>
                      )}
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <span>{product.name}</span>

                      <h5 className="float-end">${product.pricing.price}</h5>
                    </div>
                  </ListGroupItem>
                ))}

                {/* <ListGroupItem onClick={() => { gotoFree();}}>
                    <i className="mdi mdi-checkbox-blank-outline iki-light"></i> 
                     <span>&nbsp;&nbsp;&nbsp;</span>30 Minutes Free Access </ListGroupItem>
                  */}
              </ListGroup>
            </div>

            <div className="iki-btn-group mb-4">
              <div>
                <Button className="btn-sm btn-light float-end" onClick={goBack}>
                  <i className="mdi mdi-arrow-left"></i> Back
                </Button>
              </div>
              <div>
                <Button
                  className="btn-md btn-primary float-end"
                  type="submit"
                  onClick={() => {
                    gotoCart();
                  }}
                  disabled={cartDisabled}
                >
                  View Cart <i className="mdi mdi-arrow-right right"></i>
                </Button>
              </div>
            </div>
            {/* <p className="iki-sm-txt mb-4">All costs are in TT Dollars.</p> */}
          </div>
          {/* <Footy /> */}
        </>
      );
    }
  } else {
    // console.log('No user yet, redirect to home');
    goHomeNow();

    return (
      <>
        <Navi />
        {product_activated ? (
                <div className="iki-current-active fs-6">
                  Your current plan: <strong>{product_activated}</strong>
                </div>
              ) : (
                ""
              )}
        <Toast onClose={goHomeNow}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">No user</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Redirecting to signup in {counter} seconds.</Toast.Body>
        </Toast>
      </>
    );
  }
}

export default Services;
