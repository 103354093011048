import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from './nav';

import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';

import Select from 'react-select';


import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import '../App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

const api = axios.create({    
  // https://api.switchtt.a2hosted.com
  baseURL: process.env.REACT_APP_API_URL 
  //   'https://switchtt.sytes.net/api'
})

const selfservice = axios.create({
    baseURL: process.env.REACT_APP_SELF_SERVICE_URL,
    headers: {
        'api_key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
    }
})



function Reset() {

    const user_missing = (localStorage.getItem('user') == 'empty' || localStorage.getItem('user') === null);
    const [contactCode, setContactCode] = useState('');
    const [loginResponse, setLoginResponse] = useState("");
    const [loginResponseBackground, setLoginResponseBackground] = useState("alert alert-light");
    const [newPassword, setNewPassword] = useState('');


    const goHomeNow =  async event => {
        window.location.replace('/');
    }

    async function resetPassword(evt) {
        evt.preventDefault();
        // selfservice.get(
        //     '/contacts/'+ JSON.parse(localStorage.getItem('user')).id
        // ).then(res => {
        //     let code = res.data.code;
            console.log('Contact code: ', localStorage.getItem('contact_code'));
            api.post(
                '/crmcom/contacts/' + localStorage.getItem('contact_code') + '/change_password',
                { 
                    password: newPassword
                }
            ).then(res => {
                console.log('Change password result: ', res.data);
                if(res.data.status == 400){
                    setLoginResponse(res.data.message + ': ' + res.data.parameters[0])
                    setLoginResponseBackground("alert alert-danger")
                } else {
                    setLoginResponse('Password successfully set');
                    setLoginResponseBackground("alert alert-success")
                }
            }).catch(error => {
                console.log('Change password error: ', error);
            })
        // })

    }
    
    
    function changeNewPassword({ target }) {
        console.log('Password changing...');
        setNewPassword(target.value);
    }



    useEffect(() => { 



    }, []);

    if(!user_missing) { 

        return (
            <>
                <Navi />
                <Form  onSubmit={resetPassword} >
                    <div className="iki-form-container-login">

                        

                        <div className="fs-2 mb-3 fw-bold  text-center text-secondary">Reset Password</div>

                        <div className="iki-form-desc text-center">
                                <div 
                                role="alert">{loginResponse}
                                </div>
                        </div>


                    
                        
                        {/* <Form.Group className="mb-3" controlId="formCurrentPassword">
                            <Form.Control type="password" placeholder="Current Password" required />
                        </Form.Group> */}
                        <Form.Group className="mb-3  pt-3 " controlId="formNewPassword">
                            <Form.Control type="password" placeholder="Enter new password" value={newPassword} onChange={changeNewPassword} required />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formAuthPassword">
                            <Form.Control type="password" placeholder="New Password Confirmation" required />
                        </Form.Group> */}
                        

                        <div className="iki-row-item-button">
                            <Button variant="primary" type="submit" size="lg">
                                Reset Password
                            </Button>
                        </div>
                        
                        
                    </div>
                </Form>
            </>        
        )

    } else {

        goHomeNow();

    }

}

export default Reset;