import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Navi from './nav';

import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';

import Select from 'react-select';


import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import '../App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

const api = axios.create({    
  // https://api.switchtt.a2hosted.com
  baseURL: process.env.REACT_APP_API_URL
  //   'https://switchtt.sytes.net/api'
})


const selfservice = axios.create({
    baseURL: process.env.REACT_APP_SELF_SERVICE_URL,
    headers: {
        api_key: process.env.REACT_APP_API_KEY
    }
})



function Forgot() {

    const user_missing = (localStorage.getItem('user') == 'empty' || localStorage.getItem('user') === null);

    // const [searchParams, setSearchParams] = useSearchParams();
    const [resetEmail, setResetEmail] = useState('');
    const [loginResponse, setLoginResponse] = useState("Send password reset email");
    const [loginResponseBackground, setLoginResponseBackground] = useState("alert alert-light");
 
    
    // const user_missing = true; 
    
    const goHomeNow =  async event => {
        window.location.replace('/');
    }

    async function sendReset(evt) {
        evt.preventDefault();
        console.log('Attempting to send password reset...');
        selfservice.post(
            '/contacts/forgot_password',
            { 
                username: resetEmail
            }
        ).then(res => {
            console.log('Send password reset result: ', res.data);
            if(res.data.status == 400){
                setLoginResponse(res.data.message + ': ' + res.data.parameters[0])
                setLoginResponseBackground("alert alert-danger")
            } else {
                setLoginResponse('Password reset link successfully sent');
                setLoginResponseBackground("alert alert-success")
            }
        }).catch(error => {
            console.log('Send password reset link error: ', error);
        })
    }

    function changeResetEmail({ target }) {
        // console.log('Password changing...');
        setResetEmail(target.value);
    }

    useEffect(() => { 

    }, []);

    if(user_missing) { 

        return (
            <>
                <Navi />
                <div className="page-landing col-sm-12 col-md-6 col-lg-6 text-center">

                    <h5>Forgot password </h5>

                    <Form onSubmit={sendReset} >


                        <div className="iki-form-container-login">


                                <div className="iki-form-desc text-center">
                                        <div className={loginResponseBackground} role="alert">{loginResponse}</div>
                                </div>

                                
                                <Form.Group className="mb-3" controlId="formBasicUsername">
                                    <Form.Control type="text" placeholder="Email" value={resetEmail} onChange={changeResetEmail} required />
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Control type="password" placeholder="New Password" value={newPassword} onChange={changeNewPassword} required />
                                </Form.Group> */}
                                {/* <Form.Group className="mb-3" controlId="formAuthPassword">
                                    <Form.Control type="password" placeholder="New Password Confirmation" required />
                                </Form.Group> */}
                                

                                <div className="iki-row-item-button">
                                    <Button variant="primary" type="submit" size="lg">
                                        Send Reset Link
                                    </Button>
                                </div>

                            </div>                        
                    
                    </Form>
                </div>
            </>        
        )

    } else {

        goHomeNow();

    }

}

export default Forgot;