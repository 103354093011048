import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footy from "./footer";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import { useEffect } from "react";

import { useParams } from "react-router-dom";

import axios from "axios";
axios.defaults.withCredentials = true;

import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const api = axios.create({
  // https://api.switchtt.a2hosted.com
  // baseURL: 'https://switchtt.sytes.net/api'
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://localhost:4001/api",
});

const crm = axios.create({
  //baseURL: 'https://sandbox.crm.com/backoffice/v1',
  baseURL: process.env.REACT_APP_API_URL + "/crmcom",
  // baseURL: 'https://switchtt.a2hosted.com/api/crmcom',
  // baseURL: "https://localhost:4001/api/crmcom",
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
    // "637da14a-6e03-4fcb-831b-3ad1700213dd",
  },
});

function Estimate() {
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState({});
  const activeStep = 1;

  const [confirmationResponse, setConfirmationResponse] = useState("");
  const [showItems, setShowItems] = useState(false);
  const [confirmationResponseBackground, setConfirmationResponseBackground] =
    useState("alert alert-light");
  const [loading, setLoading] = useState(false);
  const [hideCartEmpty, setHideCartEmpty] = useState(false);

  const goHomeNow = async (event) => {
    // window.location.replace('/');
  };

  const navigateToServices = () => {
    const intervalId = setInterval(() => {
      // Navigate to /services after 5 seconds
      navigate("/services");
      clearInterval(intervalId); // Clear the interval after navigating
    }, 3000);
  };

  function deleteCartItem(evt) {
    setLoading(true);
    // console.log(JSON.stringify(localStorage.getItem("active_product")))
    if (localStorage.getItem("product")) {
      localStorage.removeItem("product");
      navigate("/services");
    }
    setShowItems(false);
    setLoading(false);
  }

  function doCheckout(evt) {
    setLoading(true);

    // console.log(
    //   "Stored product: ",
    //   JSON.parse(localStorage.getItem("product"))?.pricing.id || ""
    // );
    // console.log(
    //   "Stored user: ",
    //   JSON.parse(localStorage.getItem("user") || {})
    // );
    // console.log(
    //   "Stored accounts: ",
    //   JSON.parse(localStorage.getItem("accounts")) || []
    // );
    // console.log(
    //   "Stored payment method: ",
    //   JSON.parse(localStorage.getItem("payment_method")) || {}
    // );
    let price_terms_id =
      JSON.parse(localStorage.getItem("product"))?.pricing.id || "";
    let thePrice =
      JSON.parse(localStorage.getItem("product")).pricing.price || 0;

    // return
    crm
      .post("/estimates/orders", {
        account_id: JSON.parse(localStorage.getItem("accounts"))[0].id || [],
        supply_method: "DIRECT_SALE",
        fullfilled_by: "",
        use_wallet_funds: "false",
        payment_method_type: "CARD",
        line_items: [
          {
            id: JSON.parse(localStorage.getItem("product")).id || {},
            quantity: 1,
            price: thePrice,
            tax_model: "TAX_INCLUSIVE",
            notes: "",
            price_terms_id: price_terms_id,
          },
        ],
      })
      .then((res) => {
        console.log(res);

        // console.log("Create Order Estimate result: ", res?.data);
        if (res?.data?.status != 200) {
          setConfirmationResponseBackground("alert alert-danger");
          setConfirmationResponse(res?.data?.message || "");
        }

        localStorage.setItem("tid", res?.data?.id) || "";
        localStorage.setItem("oid", res?.data?.order_estimate?.queue_id) || "";
        // return
        // setLoading(false)
        // return
        navigate("/checkout");
        return;

        //genertate payment method
        api.post("/crmcom/orders", {});

        api
          .post("/crmcom/orders", {
            estimation_id: res?.data?.id || "",
            payments: [
              {
                amount:
                  res?.data?.service_delivery_estimate[0]?.billing_estimate
                    ?.totals?.total_amount,
                payment_method: {
                  type: "CARD",
                  id:
                    JSON.parse(localStorage.getItem("payment_method"))?.id ||
                    "",
                },
                //   "payment_method_type": "CARD",
                //   "paid_on": "ON_ORDER",
                //   "payment_method_id": JSON.parse(localStorage.getItem('payment_method')).id,
                //   "amount": res?.data?.service_delivery_estimate[0].billing_estimate.totals.total_amount,
                //   "payment_token": ""
              },
            ],
            notes: "",
            use_wallet_funds: "false",
          })
          .then((res) => {
            setLoading(false);
            if (res?.data?.status != 200) {
              setConfirmationResponseBackground("alert alert-danger");
              setConfirmationResponse(res?.data?.message);
              setHideCartEmpty(true);
            }
            // console.log("Order Creation result : ", res?.data || "");
            localStorage.setItem("order_id", res?.data?.id || "");
            // console.log("Navigating to confirmation");
            navigate("/checkout");
          });
      })
      .catch((error) => {
        // console.log(
        //   "Create Order Estimate error: ",
        //   error?.response?.data || ""
        // );

        toast.error("Service already exists!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });

        setConfirmationResponseBackground("alert alert-danger");
        setConfirmationResponse(error?.data?.message || "");
        deleteCartItem();
        navigateToServices();
      });
  }

  const product_missing =
    localStorage.getItem("product") == "empty" ||
    localStorage.getItem("product") === null;

  const handleToastClose = () => {
    navigate("/services");
  };

  useEffect(() => {
    setPaymentMethod(JSON.parse(localStorage.getItem("payment_method")));

    if (localStorage.getItem("product")) {
      setShowItems(true);
    } else {
      setShowItems(false);
      // navigate('/services');
    }

    if (localStorage.getItem("user")) {
      api
        .get(
          "/crmcom/contacts/" +
            (JSON.parse(localStorage.getItem("user"))?.id || "") +
            "/services"
        )
        .then((res) => {
          if (!product_missing) {
            // if(typeof res.data.active_services.find(o => o.product_id === JSON.parse(localStorage.getItem('product')).id) === 'undefined') {
            //   console.log('Active plan selected for purchase');
            //   navigate('/services');
            // } else {
            //   console.log('Plan selected for purchase is not active');
            // }
          }
        });
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="dark"
      />

      <Navi />
      <div>
        <ProgressBar activeStep={activeStep} />
      </div>
      {/* use a toast instead  */}
      <div className="iki-form-desc text-center">
        <div
          className={confirmationResponseBackground + "p-0 m-0 pe-0 ps-0"}
          role="alert"
        >
          {/* {confirmationResponse} */}
        </div>
      </div>
      {/* use a toast instead  */}
      <div className="fs-1 fw-bold text-secondary">Cart</div>
      <div className="page-estimate   col-lg-6 col-sm-12 col-md-12">
        <Card className="mt-0  px-2 pb-3 pt-3">
          <Card.Header className="bg-transparent p-0 border-0 text-secondary fs-7"></Card.Header>
          <Card.Body className="p-0">
            {!loading && showItems ? (
              <p>Please confirm your selection </p>
            ) : (
              <>
                {hideCartEmpty ? (
                  <>
                    Cart is empty. Please <a href="/services">select a plan.</a>
                  </>
                ) : (
                  <>A new plan cannot be added to your account at this time.</>
                )}
              </>
            )}

            <>
              {loading ? (
                <Row className="text-center mt-3">
                  <Col>
                    <div
                      className="page-loading iki-spinner spinner-border"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>

            {!loading && showItems ? (
              <>
                <div className="col-12">
                  <div className="col-12 row pb-1 d-flex align-items-center border-bottom m-0">
                    <div className="col-9 px-0 col-md-9">
                      <div className="btn-sm btn-light float-start">
                        <strong>
                          {JSON.parse(localStorage.getItem("product"))?.name ||
                            ""}
                        </strong>
                      </div>
                    </div>
                    <div className="col-3 col-md-3 sw-crt-rw p-0 d-flex justify-content-between">
                      <div className="float-start sw-gr mx-1">
                        <strong>
                          $
                          {JSON.parse(localStorage.getItem("product"))?.pricing
                            ?.price || ""}
                        </strong>
                      </div>
                      <div className="float-end">
                        <Button
                          type="button"
                          className="btn btn-black btn-sm"
                          onClick={() => deleteCartItem()}
                        >
                          X
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="mt-4 mb-0 text-center pe-3 ps-3 p-2 small sw-refund-alert">
                    Please note, SwitchTT has a{" "}
                    <strong>no refund policy</strong> for the services we offer.
                    By clicking, "Use this payment method", you agree to
                    the terms of our <a href="/refund-policy">Refund Policy</a>
                </div> */}

                <div className="pt-2 mt-2">
                  <Button
                    className="btn-md btn-primary float-end"
                    type="submit"
                    onClick={doCheckout}
                  >
                    Proceed to Checkout
                    <i className="mdi mdi-arrow-right right"></i>
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
      {/* <Footy /> */}
    </>
  );
}

export default Estimate;
