import {
  React,
  Component,
  createContext,
  useContext,
  useState,
  useRef,
} from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import Toast from "react-bootstrap/Toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
axios.defaults.withCredentials = true;


// Create a context
export const TokenContext = createContext();

const api = axios.create({
  // https://api.switchtt.a2hosted.com
  baseURL: process.env.REACT_APP_API_URL
  // baseURL: "https://localhost:4001/api",
});

const crm = axios.create({
  //baseURL: 'https://sandbox.crm.com/backoffice/v1',
  baseURL: process.env.REACT_APP_API_URL + '/crmcom',
  // baseURL: "https://localhost:4001/api/crmcom",
  headers: {
    api_key: process.env.REACT_APP_API_KEY
    //"637da14a-6e03-4fcb-831b-3ad1700213dd",
  },
});

function Estimate() {
  const iframeRef = useRef(null);

  // const handleLoad = () => {
  //     const iframe = iframeRef.current;
  //     const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

  //     // Clear out the iframe contents
  //     while (iframeDoc.body.firstChild) {
  //         iframeDoc.body.removeChild(iframeDoc.body.firstChild);
  //     }

  //     // Add the "Loading..." text
  //     iframeDoc.body.innerHTML = '<p>Loading...</p>';
  // };

  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState({});
  const [confirmationResponse, setConfirmationResponse] = useState("");
  const [confirmationResponseBackground, setConfirmationResponseBackground] =
    useState("alert alert-light");
  const [iframeCode, setIframeCode] = useState("");
  const [loadIframe, setLoadIframe] = useState(false);
  const [srcDoc, setSrcDoc] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [height, setHeight] = useState("300px"); // initial height

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const goHomeNow = async (event) => {
    window.location.replace("/");
  };

  function doCheckout(evt) {
    setLoading(true);

    // experimental to load the iframe
    //send a call to the face test on local host
    crm
      .post(
        "https://switchtt.sytes.net/fac/authRequest",
        {
          account_id: JSON.parse(localStorage.getItem("accounts"))[0].id,
          supply_method: "DIRECT_SALE",
          fullfilled_by: "",
          use_wallet_funds: "false",
          payment_method_type: "CARD",
          line_items: [
            {
              id: JSON.parse(localStorage.getItem("product")).id,
              quantity: 1,
              price: "75",
              tax_model: "TAX_INCLUSIVE",
              notes: "",
              price_terms_id: "price_terms_id",
            },
          ],
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setSrcDoc(res.data);
        const timer = setTimeout(() => {
          setLoading(false);
          setShowFrame(true);
        }, 1000); // Delay for 1 second(s)

        // setLoading(false);
        return;
      })
      .catch((error) => {
        console.log("Error: ", error);
        setLoading(false);
      });

    //inject the data into the iframe

    ///////////////////////////////////

    return;

    console.log(
      "Stored product: ",
      JSON.parse(localStorage.getItem("product")).pricing.id
    );
    console.log("Stored user: ", JSON.parse(localStorage.getItem("user")));
    console.log(
      "Stored accounts: ",
      JSON.parse(localStorage.getItem("accounts"))
    );
    console.log(
      "Stored payment method: ",
      JSON.parse(localStorage.getItem("payment_method"))
    );
    let price_terms_id = JSON.parse(localStorage.getItem("product")).pricing.id;

    crm
      .post("/estimates/orders", {
        account_id: JSON.parse(localStorage.getItem("accounts"))[0].id,
        supply_method: "DIRECT_SALE",
        fullfilled_by: "",
        use_wallet_funds: "false",
        payment_method_type: "CARD",
        line_items: [
          {
            id: JSON.parse(localStorage.getItem("product")).id,
            quantity: 1,
            price: "75",
            tax_model: "TAX_INCLUSIVE",
            notes: "",
            price_terms_id: price_terms_id,
          },
        ],
      })
      .then((res) => {
        console.log("Create Order Estimate result: ", res.data);
        if (res.data.status != 200) {
          setConfirmationResponseBackground("alert alert-danger");
          setConfirmationResponse(res.data.message);
        }
        api
          .post("/crmcom/orders", {
            estimation_id: res.data.id,
            payments: [
              {
                payment_method_type: "CARD",
                paid_on: "ON_ORDER",
                payment_method_id: JSON.parse(
                  localStorage.getItem("payment_method")
                ).id,
                amount: 75.0,
                payment_token: "",
              },
            ],
            notes: "",
            use_wallet_funds: "false",
          })
          .then((res) => {
            setLoading(false);
            if (res.data.status != 200) {
              setConfirmationResponseBackground("alert alert-danger");
              setConfirmationResponse(res.data.message);
            }
            console.log("Order Creation result : ", res.data);
            localStorage.setItem("order_id", res.data.id);
            console.log("Navigating to confirmation");
            navigate("/confirmation");
          });
      })
      .catch((error) => {
        console.log("Create Order Estimate error: ", error.response.data);
        setConfirmationResponseBackground("alert alert-danger");
        setConfirmationResponse(res.data.message);
        setLoading(false);
      });
  }

  const product_missing =
    localStorage.getItem("product") == "empty" ||
    localStorage.getItem("product") === null;

  useEffect(() => {
    setPaymentMethod(JSON.parse(localStorage.getItem("payment_method")));
    // console.log('Selected Payment Method: ', JSON.parse(localStorage.getItem('payment_method')));

    api
      .get(
        "/crmcom/contacts/" +
          JSON.parse(localStorage.getItem("user")).id +
          "/services"
      )
      .then((res) => {
        if (!product_missing) {
          // if(typeof res.data.active_services.find(o => o.product_id === JSON.parse(localStorage.getItem('product')).id) === 'undefined') {
          //   console.log('Active plan selected for purchase');
          //   navigate('/services');
          // } else {
          //   console.log('Plan selected for purchase is not active');
          // }
        }
      });
  }, []);

  if (localStorage.getItem("payment_method") === null) {
    goHomeNow();

    return (
      <>
        <Navi />
        <h6>Missing payment method</h6>
      </>
    );
  } else {
    return (
      <>
        <Navi />
        <div className="page-estimate   col-lg-6 col-sm-12 col-md-12">
          <Card>
            <Card.Header>
              <strong>Checkout Details</strong>
            </Card.Header>
            <Card.Body>
              <div className="iki-form-desc text-center">
                <div className={confirmationResponseBackground} role="alert">
                  {confirmationResponse}
                </div>
              </div>

              <>
                {loading ? (
                  <Row className="text-center">
                    <Col>
                      <div
                        className="page-loading iki-spinner spinner-border"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>

              {!loading ? (
                <>
                  <div className="iki-btn-group">
                    <div>
                      <div className="btn-sm btn-light float-end">
                        <strong>
                          {JSON.parse(localStorage.getItem("product")).name}
                        </strong>
                      </div>
                    </div>
                    <div>
                      <div className="float-end" type="submit">
                        <strong>
                          $
                          {
                            JSON.parse(localStorage.getItem("product")).pricing
                              .price
                          }
                        </strong>
                      </div>
                    </div>
                  </div>

                  <div className="iki-sm-txt-left">Payment Method</div>
                  <h6>
                    {
                      JSON.parse(localStorage.getItem("payment_method")).card
                        .card_holder_details.card_holder_name
                    }
                  </h6>

                  <div className="iki-card-details">
                    <p className="text-primary">
                      {
                        JSON.parse(localStorage.getItem("payment_method")).card
                          .brand
                      }
                    </p>
                    <p>
                      {JSON.parse(localStorage.getItem("payment_method")).card
                        .first6 +
                        "******" +
                        JSON.parse(localStorage.getItem("payment_method")).card
                          .last4}
                    </p>
                  </div>

                  <div></div>

                  <div>
                    <Button
                      className="btn-sm btn-primary float-end"
                      type="submit"
                      onClick={doCheckout}
                    >
                      Checkout<i className="mdi mdi-arrow-right right"></i>
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          height="100vh"
          // style={{ height: height }}

          dialogClassName="modal-fullscreen"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center' 
            }}>
                <Spinner />
            </div> */}

            <iframe
              className="fac-iframe"
              ref={iframeRef}
              srcDoc={srcDoc}
              onLoad={() => {
                setLoading(false);
              }}
              style={{ border: "none" }}
              title="FAC"
              width="100%"
              height="100%"
            />
            {loading && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Spinner /> */}
                </div>
                <div
                  className="page-loading iki-spinner spinner-border"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              </>
            )}
          </Modal.Body>
          {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer> */}
        </Modal>
      </>
    );
  }
}

export default Estimate;
