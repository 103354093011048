import { React, Component, useRef, useState, useMemo } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import { format , differenceInDays} from "date-fns";

import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";

import Select from "react-select";
import countryList from "react-select-country-list";

import axios from "axios";

import "../App.css";
import "../history.css";
import "bootstrap/dist/css/bootstrap.min.css";

import myVideo from "../tobago.mp4";

import { useEffect } from "react";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "http://localhost:4001/api",
});

const selfservice = axios.create({
  baseURL: process.env.REACT_APP_SELF_SERVICE_URL,
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
  },
});

const Account = () => {
  const [plans, setPlans] = useState({
    active_services: [],
    inactive_services: [],
  });

  const navigate = useNavigate();
  const iframeRef = useRef(null);

  const [loaded, setLoaded] = useState(false);

  const getCountryISO3 = require("country-iso-2-to-3");
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "pk_test_51GrQ8HDhgyRWLej3f3QOlHlNjkrr55NV1z4zDwR8I6wioivwv3ES9x7a4JPH3xY6LOCXBUI1IHee0tv4uO3uSlMq00wtUVA7Cu",
  };

  const CurrentYear = new Date().getFullYear();

  const [paymentName, setPaymentName] = useState("");
  const [paymentCCNumber, setPaymentCCNumber] = useState("");
  const [paymentMonth, setPaymentMonth] = useState("01");
  // const [paymentYear, setPaymentYear] = useState("");
  const [paymentYear, setPaymentYear] = useState(String(CurrentYear).slice(-2));

  // const [paymentName, setPaymentName] = useState("");
  // const [paymentCCNumber, setPaymentCCNumber] = useState("");
  // const [paymentMonth, setPaymentMonth] = useState(1);
  // const [paymentYear, setPaymentYear] = useState("");
  const [paymentCCV, setPaymentCCV] = useState("");
  const [paymentAddress1, setPaymentAddress1] = useState("");
  const [paymentAddress2, setPaymentAddress2] = useState("");
  const [paymentCity, setPaymentCity] = useState("");
  const [paymentState, setPaymentState] = useState("");
  const [paymentZip, setPaymentZip] = useState("");
  const [paymentCountry, setPaymentCountry] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentCards, set_cards] = useState([]);
  const [counter, setCounter] = useState(10);
  const [showFrame, setShowFrame] = useState(false);
  const [show, setShow] = useState(false);
  const [showAddNewCard, setShowAddNew] = useState(false);
  const [showCardError, setshowCardError] = useState(false);
  const [browserInfo, setBrowserInfo] = useState({});

  const [srcDoc, setSrcDoc] = useState("");
  const [addCardResponse, setAddCardResponse] = useState("");
  const [addCardResponseBackground, setAddCardResponseBackground] =
    useState("alert alert-light");
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(1);
  const [error, setError] = useState(null);

  const [active, setActive] = useState(-1);

  const [cardSelected, setCardSelected] = useState(false);
  const [showCardUnselected, setShowCardUnselected] = useState(false);

  const countries = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    try {
      const BrowserInfo = {
        AcceptHeader: window.navigator.languages[0] || "N/A",
        Language: window.navigator.language || "N/A",
        ScreenHeight: window.screen.height || "N/A",
        ScreenWidth: window.screen.width || "N/A",
        TimeZone: new Date().getTimezoneOffset() / -60 || "N/A",
        UserAgent: window.navigator.userAgent || "N/A",
        IP: "Not accessible from client side",
        JavaEnabled: window.navigator.javaEnabled() ? "True" : "False",
        JavascriptEnabled: true,
        ColorDepth: window.screen.colorDepth || "N/A",
      };
      setBrowserInfo(BrowserInfo);
      console.log(BrowserInfo);
      // return
    } catch (err) {
      setError(err.message);
    }
  }, []);

  // function saveCard() {
  //   console.log("Saving Card...");
  //   let payment_method = {
  //     TransactionIdentifier: localStorage.getItem("tid") || "",
  //     first6: paymentCCNumber.substring(0, 6),
  //     last4: paymentCCNumber.substring(6, 10),
  //     expiration_month: paymentMonth,
  //     expiration_year: paymentYear,
  //     Source: {
  //       CardPan: paymentCCNumber,
  //       CardCvv: paymentCCV,
  //       CardExpiration: paymentYear + paymentMonth,
  //       CardholderName: paymentName,
  //     },
  //     OrderIdentifier: localStorage.getItem("oid") || "",
  //     card_holder_details: {
  //       card_holder_name: paymentName || "",
  //       address_line_1: paymentAddress1 || "",
  //       address_line_2: paymentAddress2 || "",
  //       address_city: paymentCity || "",
  //       address_zip: paymentZip || "",
  //       address_state: paymentState || "",
  //       address_country: paymentCountry || "",
  //       use_billing_address: true,
  //     },
  //     BrowserInfo: browserInfo,
  //   };

  //   api
  //     .post(
  //       "/crmcom/contacts/" +
  //         localStorage.getItem("contact_id") +
  //         "/payment_methods",
  //       payment_method
  //     )
  //     .then((res) => {
  //       console.log("--------- RESPONSE FROM TOKENIZATION ----------------");

  //       console.log(res);
  //       setSrcDoc(res?.data?.RiskManagement?.ThreeDSecure?.RedirectData || "");
  //       setShowFrame(true);
  //       return;
  //     })
  //     .catch((err) => {
  //       console.log(err);

  //       toast.error(
  //         "Card cannot be used. Please try another Debit or Credit card",
  //         {
  //           position: "top-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         }
  //       );
  //     });
  // }

  function saveCard() {
    console.log("Saving Card...");
    let payment_method = {
      component: "account",
      TransactionIdentifier: localStorage.getItem("tid") || "",
      first6: paymentCCNumber.substring(0, 6),
      last4: paymentCCNumber.substring(6, 10),
      expiration_month: paymentMonth,
      expiration_year: paymentYear,
      Source: {
        CardPan: paymentCCNumber,
        CardCvv: paymentCCV,
        CardExpiration: paymentYear + paymentMonth,
        CardholderName: paymentName,
      },
      OrderIdentifier: localStorage.getItem("oid") || "",
      card_holder_details: {
        card_holder_name: paymentName || "",
        address_line_1: paymentAddress1 || "",
        address_line_2: paymentAddress2 || "",
        address_city: paymentCity || "",
        address_zip: paymentZip || "",
        address_state: paymentState || "",
        address_country: paymentCountry || "",
        use_billing_address: true,
      },
      BrowserInfo: browserInfo,
    };

    api
      .post(
        "/crmcom/contacts/" +
          localStorage.getItem("contact_id") +
          "/payment_methods",
        payment_method
      )
      .then((res) => {
        console.log("--------- RESPONSE FROM TOKENIZATION ----------------");

        console.log(res);
        setSrcDoc(res?.data?.RiskManagement?.ThreeDSecure?.RedirectData || "");
        setShowFrame(true);
        // navigate("/account");
        return;
      })
      .catch((err) => {
        console.log(err);
        //show toast error to user
        // setshowCardError(true);
        toast.error(
          "Card cannot be used. Please try another Debit or Credit card",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      });
  }

  function hidCardError() {
    setshowCardError(false);
  }

  // function performCheckout(evt) {
  //   evt.preventDefault();
  //   // console.log('First 6 digits of CC number: ', paymentCCNumber.substring(0, 6));
  //   // console.log('Last 4 digits of CC number: ', paymentCCNumber.substring((paymentCCNumber.length -4), paymentCCNumber.length));
  //   // console.log('Credit card brand: ', creditCardType(paymentCCNumber));
  //   let payment_method = {
  //     name: "",
  //     payment_method_type: "CARD",
  //     is_primary: true,
  //     is_backup: true,
  //     notes: "",
  //     card: {
  //       // "card_number": paymentCCNumber,
  //       // "card_cvc": paymentCCV,
  //       id: "CARD",
  //       name: "Card",
  //       first6: paymentCCNumber.substring(0, 6),
  //       last4: paymentCCNumber.substring(6, 10),
  //       brand: creditCardType(paymentCCNumber),
  //       expiration_month: paymentMonth,
  //       expiration_year: paymentYear,
  //       country_of_issue: null,
  //       card_holder_details: {
  //         card_holder_name: paymentName,
  //         address_line_1: paymentAddress1,
  //         address_line_2: paymentAddress2,
  //         address_city: paymentCity,
  //         address_zip: paymentZip,
  //         address_state: paymentState,
  //         address_country: paymentCountry,
  //         use_billing_address: true,
  //       },
  //     },
  //     gateway_token: [
  //       {
  //         gateway: "FAC",
  //         token: "",
  //         integration_id: "48a278b4-5391-4ee3-8627-8026e83617a0",
  //       },
  //     ],
  //   };

  //   // console.log('Getting Payment Method: ');
  //   console.log(JSON.stringify(payment_method, null, 2));

  //   api
  //     .post(
  //       "/crmcom/contacts/" +
  //         localStorage.getItem("contact_id") +
  //         "/payment_methods",
  //       payment_method
  //     )
  //     .then((res) => {
  //       // setProducts(res.data)
  //       // console.log('Creating payment method result: ', res.data);
  //       let payment_method_id = res.data.id;
  //       console.log("payment_method_id: ", payment_method_id);
  //       api
  //         .get(
  //           "/crmcom/contacts/" +
  //             localStorage.getItem("contact_id") +
  //             "/payment_methods"
  //         )
  //         .then((res) => {
  //           // console.log('Fetch Payment Methods result: ', res.data);
  //           //setPaymentMethods(res.data.content);
  //           console.log(res.data.content);
  //           save_all_cards(res.data.content);
  //           /*
  //         let payment_method = res.data.content.find(o => o.id === payment_method_id);
  //         console.log('Selected payment method: ', payment_method);
  //         localStorage.setItem('payment_method', JSON.stringify(payment_method));
  //         */
  //         });
  //       //navigate('/checkout');
  //       // backToAP();
  //     });

  //   // backToAP();
  // }

  const handleCloseFrame = () => setShowFrame(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShowAddNew(false);
    setShow(true);
  };

  const handleCloseAddNew = () => {
    setShow(true);
    setShowAddNew(false);
  };
  const handleShowAddNew = () => {
    setShow(false);
    console.log("Showinf Irame");
    setShowAddNew(true);
  };

  const handleShowFrame = () => {
    setShowAddNew(false);
    setShowFrame(true);
  };

  // const getCountryISO3 = require("country-iso-2-to-3");

  const changePaymentName = (e) => {
    setPaymentName(e.target.value);
    //console.log(ac_value);
  };

  function changePaymentCCNumber({ target }) {
    setPaymentCCNumber(target.value);
  }

  function changePaymentMonth({ target }) {
    setPaymentMonth(target.value);
  }

  function changePaymentYear({ target }) {
    setPaymentYear(target.value);
  }

  function changePaymentCCV({ target }) {
    setPaymentCCV(target.value);
  }

  function changePaymentCity({ target }) {
    setPaymentCity(target.value);
  }

  function changePaymentState({ target }) {
    setPaymentState(target.value);
  }

  function changePaymentZip({ target }) {
    setPaymentZip(target.value);
  }

  function changePaymentAddress2({ target }) {
    setPaymentAddress2(target.value);
  }

  function changePaymentAddress1({ target }) {
    setPaymentAddress1(target.value);
  }

  const changePaymentCountry = (value) => {
    // console.log('Country Code: ', getCountryISO3(value.value));
    setPaymentCountry(getCountryISO3(value.value));
  };

  function creditCardType(cc) {
    let amex = new RegExp("^3[47][0-9]{13}$");
    let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
    let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
    let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

    let mastercard = new RegExp("^5[1-5][0-9]{14}$");
    let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

    let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
    let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
    let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

    let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
    let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

    if (visa.test(cc)) {
      return "VISA";
    }
    if (amex.test(cc)) {
      return "AMEX";
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
      return "MASTERCARD";
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      return "DISCOVER";
    }
    if (diners.test(cc)) {
      return "DINERS";
    }
    if (jcb.test(cc)) {
      return "JCB";
    }
    if (cup1.test(cc) || cup2.test(cc)) {
      return "CHINA_UNION_PAY";
    }
    if (cc.substring(0, 10) == "4242424242") {
      return "VISA";
    }
    return undefined;
  }

  function planDate(plan_date) {
    const expirationDate = (plan_date * 1000); // Convert Unix timestamp to JavaScript Date object
    const today = new Date();
    const daysLeft = differenceInDays(expirationDate, today);
    return (`Expires in ${daysLeft} days`);
    // return format(plan_date, "iii");
    // return daysLeft

    let pdate = new Date(plan_date * 1000);
    let pdate_short = pdate.toString().substring(0, 11);
    return pdate_short;
  }

  function save_all_cards(payment_mehthods) {
    const all_payment_mehtods = payment_mehthods;
    // console.log(all_payment_mehtods);
    set_cards(all_payment_mehtods);
    goToSavedCards();
  }

  function goToSavedCards() {
    setActiveTab(1);
  }

  // function deleteCard(payment_method) {
  const deleteCard = async (payment_method) => {
    let deleteConfirmed = confirm(
      'Are you sure you want to delete this payment method?'
    );
    if (deleteConfirmed) {
      api
        .delete(
          "crmcom/contacts/" +
            JSON.parse(localStorage.getItem("user")).id +
            "/payment_methods/" +
            payment_method.id
        )
        .then((res) => {
          // console.log('Delete card result', res.data);

          api
            .get(
              "/crmcom/contacts/" +
                localStorage.getItem("contact_id") +
                "/payment_methods"
            )
            .then((res) => {
              console.log(res.data);
              setLoaded(true);
              //return
              //setPaymentMethods(res.data.content);
              // set_cards(res.data);
              save_all_cards(res.data);
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  };

  // function deleteCard(payment_method) {
  const makePrimaryPM = async (payment_method) => {
    let primaryConfirmed = confirm(
      'Do you want to make "' +
        payment_method.card.card_holder_details.card_holder_name +
        '" your primary payment method ?'
    );
    if (primaryConfirmed) {
      api
        .put(
          "/crmcom/contacts/" +
            JSON.parse(localStorage.getItem("user")).id +
            "/payment_methods/" +
            payment_method.id,
          { is_primary: "true" }
        )
        .then((res) => {
          // console.log('Make primary payment method result', res.data);
          api
            .get(
              "/crmcom/contacts/" +
                JSON.parse(localStorage.getItem("user")).id +
                "/payment_methods"
            )
            .then((res) => {
              // console.log(res.data.content)
              setLoaded(true);
              //return
              save_all_cards(res.data);
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  };

  const goHomeNow = async (event) => {
    window.location.replace("/");
  };

  const user_missing =
    localStorage.getItem("user") == "empty" ||
    localStorage.getItem("user") === null;

  useEffect(() => {
    if (!user_missing) {
      api
        .get(
          "/crmcom/contacts/" +
            JSON.parse(localStorage.getItem("user")).id +
            "/services"
        )
        .then((res) => {
          setLoaded(true);
          setPlans(res.data);
          console.log("Fetch plans result: ", res.data);
          exit();
          return;
          // let plan_date = new Date(res.data.active_services[0].end_date * 1000);
          // console.log('Plan Date: ' + plan_date );
        })
        .catch((error) => {
          console.log(error);
        });

      api
        .get(
          "/crmcom/contacts/" +
            JSON.parse(localStorage.getItem("user")).id +
            "/payment_methods"
        )
        .then((res) => {
          console.log(res.data);
          setLoaded(true);
          //return
          //setPaymentMethods(res.data.content);
          set_cards(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      goHomeNow();
    }
  }, []);

  if (!user_missing) {
    return (
      <>
        <Navi />

        <div className="page-history  col-lg-8 col-sm-12 col-md-12">
          <div className="row m-1">
            <Card className="iki  border-0 col-12 pb-1 mt-0 text-left">
              <h4 className="fs-6 pb-1 pt-2 text-center">Active Plans</h4>

              <div>
                <Col className="iki">
                  <ListGroup className="iki-history iki-history-active">
                    {plans.active_services.map((plan) => (
                      <ListGroupItem key={plan.product_id}>
                        <p  className="text-center fs-6 fw-bold">{plan.name}</p>
                        <div className="text-center">
                          <div className="d-flex"></div>
                          {/* <div classname=" text-sm small">{planDate(plan.end_date)}</div> */}
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              </div>
            </Card>

            {/* <Card className="iki border-0  pt-2 col-6 text-left">
              <div className="fs-6  pb-1 fw-6 text-center">Inactive Plans</div>

              <div>
                <Col className="text-center">
                  <ListGroup className="iki-history iki-history-inactive">
                    {plans.inactive_services.map((plan) => (
                      <div key={plan.product_id}>
                        <h6 className="text-center">{plan.name}</h6>
                        <p className="fs-6 text-center">
                          {"Exp: " + planDate(plan.end_date)}
                        </p>
                      </div>
                    ))}
                  </ListGroup>
                </Col>
              </div>
            </Card> */}
          </div>

          {/* <Row>
            <Col className="text-center">
              <h5>Manage Payment Methods</h5>
            </Col>
          </Row> */}

          <Row>
            <Tabs
              //defaultActiveKey={2}
              className="mb-3"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              id="controlled-tab-example"
            >
              <Tab eventKey={1} title="Payment Methods">
                {/* 
                            <div>
                                <h4 className="iki-sm-txt">Select a payment method below:</h4>
                            </div> */}

                <div className="page-loading">
                  <Button
                    className="ps-0 pe-0"
                    variant="link"
                    onClick={handleShowAddNew}
                  >
                    <i className="mdi mdi-plus" /> Add new payment method
                  </Button>

                  <Row className="text-center">
                    <Col>
                      <div
                        className={
                          loaded == false ? "iki-spinner spinner-border" : ""
                        }
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    </Col>
                  </Row>
                </div>

                {showCardUnselected ? (
                  <Toast className="bg-info bg-gradient" onClose={hideNoCard}>
                    <Toast.Header>
                      <h6 className="me-auto">No card selected</h6>
                    </Toast.Header>
                  </Toast>
                ) : (
                  ""
                )}

                <ListGroup className="iki-list-group">
                  {paymentCards.map((payment_method) => (
                    <ListGroupItem
                      key={payment_method.id}
                      className={
                        payment_method.is_primary ? "primary-card " : "p-0"
                      }
                    >
                      <div className="iki-contact-cards text-sm small d-flex justify-content-between">
                        <div className="col-9 text-truncate flex-grow-1">
                          {payment_method.is_primary ? (
                            <i className="mdi mdi-checkbox-blank"></i>
                          ) : (
                            <i className="mdi mdi-checkbox-blank-outline iki-light"></i>
                          )}
                          <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;{payment_method.card.brand}
                          </span>
                          <span>****</span>
                          <span>{payment_method.card.last4}</span>&nbsp; -{" "}
                          <span>
                            {
                              payment_method.card.card_holder_details
                                .card_holder_name
                            }
                          </span>{" "}
                        </div>
                        <div className="">
                          {payment_method.is_primary ? (
                            <span className="iki-card-default">DEFAULT</span>
                          ) : (
                            ""
                          )}

                          {payment_method.is_primary ? (
                            <></>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => makePrimaryPM(payment_method)}
                              >
                                D
                              </button>{" "}
                              &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => deleteCard(payment_method)}
                              >
                                X
                              </button>
                            </>
                          )}
                          {/* <span className='text-danger' >
                                                x
                                            </span> */}
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>

                {/* <div className="iki-btn-group">
                                <div>
                                    <Button className="btn-sm btn-light float-end" onClick={goBack}>
                                    <i className="mdi mdi-arrow-left"></i> Back
                                    </Button>
                                </div>
                                <div>
                                    <Button className="btn-sm btn-primary float-end" type="submit"
                                        onClick={() => { proceed_to_order();}}
                                        >
                                        Proceed to Checkout <i className="mdi mdi-arrow-right right"></i> 
                                    </Button>
                                </div>
                                </div> */}
              </Tab>

              {/* <Tab eventKey={2} title="Add New Card" icon="mdi mdi-checkbox-blank"> */}
            </Tabs>
          </Row>
        </div>

        <Modal
          show={showFrame}
          onHide={handleCloseFrame}
          height="100vh"
          // style={{ height: height }}

          dialogClassName="modal-fullscreen"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              className="fac-iframe"
              ref={iframeRef}
              srcDoc={srcDoc}
              onLoad={() => {
                setLoading(false);
              }}
              style={{ border: "none" }}
              title="FAC"
              width="100%"
              height="100%"
            />
            {loading && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Spinner /> */}
                </div>
                <div
                  className="page-loading iki-spinner spinner-border"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={showAddNewCard}
          onHide={handleCloseAddNew}
          dialogClassName="lg"
        >
          <Modal.Header closeButton className="pb-0">
            <Modal.Title>Add Card</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-0 pt-0">
            {showCardError ? (
              <Toast
                style={{
                  backgroundColor: "#f00",
                  color: "#fff",
                  borderRadius: 0,
                }}
                onClose={hidCardError}
                className="mt-2"
              >
                <Toast.Header>
                  <p>
                    Error adding card. Please try another debit or credit card.
                  </p>
                </Toast.Header>
              </Toast>
            ) : (
              ""
            )}

            <Card className="border-0">
              {/* <Card.Header>
                          <strong>Card Details</strong>
                        </Card.Header> */}
              {/* <strong>Card Details</strong> */}
              <Form onSubmit={saveCard}>
                <Card.Body>
                  <Row>
                    <Col className="col-sm-12">
                      <div className="form-group">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                          value={paymentName}
                          onChange={changePaymentName}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-sm-12">
                      <div className="form-group">
                        <Form.Label>Credit Card Number</Form.Label>
                        <div className="input-group">
                          <Form.Control
                            type="text"
                            placeholder="0000 0000 0000 0000"
                            maxlenght="10"
                            value={paymentCCNumber}
                            onChange={changePaymentCCNumber}
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-credit-card"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="form-group col-sm-4">
                      <Form.Label>Month</Form.Label>
                      <Form.Select
                        value={paymentMonth}
                        onChange={changePaymentMonth}
                        required
                      >
                        <option value="01">1</option>
                        <option value="02">2</option>
                        <option value="03">3</option>
                        <option value="04">4</option>
                        <option value="05">5</option>
                        <option value="06">6</option>
                        <option value="07">7</option>
                        <option value="08">8</option>
                        <option value="09">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </Form.Select>
                    </Col>
                    <Col className="form-group col-sm-4">
                      <Form.Label>Year</Form.Label>
                      <Form.Select
                        value={paymentYear}
                        onChange={changePaymentYear}
                        required
                      >
                        {[...Array(16)].map((_, i) => {
                          const year = CurrentYear + i;
                          const lastTwoDigits = String(year).slice(-2);
                          return (
                            <option key={lastTwoDigits} value={lastTwoDigits}>
                              {year}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                    <Col className="form-group col-sm-4">
                      <Form.Label>CVV/CVC</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="123"
                        value={paymentCCV}
                        onChange={changePaymentCCV}
                        required
                      />
                      <br />
                    </Col>
                  </Row>

                  <Row>
                    <Card.Header>
                      <strong>Billing Address</strong>
                    </Card.Header>
                    <br />
                    <Col className="form-group">
                      <br />
                      {/* <Form.Label>Address</Form.Label> */}
                      <Form.Control
                        type="text"
                        placeholder="Street"
                        value={paymentAddress1}
                        onChange={changePaymentAddress1}
                        required
                      />
                    </Col>
                  </Row>
                  {/* <Row>
                              <Col className="form-group">
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Address Line 2"
                                  value={paymentAddress2}
                                  onChange={changePaymentAddress2}
                                  required
                                />
                              </Col>
                            </Row> */}
                  <Row>
                    {/* <Col className="form-group col-sm-4">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="State"
                                  value={paymentState}
                                  onChange={changePaymentState}
                                  required
                                />
                              </Col> */}
                    <Col className="form-group col-sm-12">
                      {/* <Form.Label>City</Form.Label> */}
                      <Form.Control
                        type="text"
                        placeholder="City"
                        value={paymentCity}
                        onChange={changePaymentCity}
                        required
                      />
                    </Col>
                    {/* <Col className="form-group col-sm-4">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Zip"
                                  value={paymentZip}
                                  onChange={changePaymentZip}
                                  required
                                />
                              </Col> */}
                  </Row>
                  <Row>
                    <Col className="form-group">
                      {/* <Form.Label>Country</Form.Label> */}
                      <Select
                        options={countries}
                        onChange={changePaymentCountry}
                        required
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="border-0 bg-transparent">
                  <div className="iki-btn-group m-0">
                    <div>
                      <Button
                        onClick={handleShow}
                        className="btn-md btn-light float-end"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="btn-md btn-primary float-end"
                        onClick={saveCard}
                      >
                        Save Card
                      </Button>
                    </div>
                  </div>
                </Card.Footer>
              </Form>
            </Card>
          </Modal.Body>
          {/* <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                        </Modal.Footer> */}
        </Modal>
      </>
    );
  } else {
    goHomeNow();
  }
};

export default Account;
