import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const api = axios.create({
  // https://api.switchtt.a2hosted.com
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://localhost:4001/api",
});

const crm = axios.create({
  //baseURL: 'https://sandbox.crm.com/backoffice/v1',
  baseURL: process.env.REACT_APP_API_URL + "/crmcom",
  // baseURL: 'https://switchtt.a2hosted.com/api/crmcom',
  //   baseURL: "https://localhost:4001/api/crmcom",
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
  },
});

function Company() {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState({});
  const activeStep = 1;
  const [confirmationResponse, setConfirmationResponse] = useState("");
  const [showItems, setShowItems] = useState(false);
  const [confirmationResponseBackground, setConfirmationResponseBackground] =
    useState("alert alert-light");
  const [loading, setLoading] = useState(false);
  const [hideCartEmpty, setHideCartEmpty] = useState(false);
  const [orders, setOrders] = useState([]);

  return (
    <>
      <Navi />

      <div className="page-sw   col-lg-8 col-sm-12 col-md-12">
        <>
          <h1 className="fs-2 fw-bold mb-3">
            About Us
          </h1>
          <p>SwitchTT is a leading provider of internet and IPTV services based in Signal Hill, Tobago. We're committed to delivering high-quality, reliable, and affordable services that keep our customers connected. Our dedication to customer satisfaction and continual innovation has positioned us as a trusted provider in the telecommunications industry.</p>

<p>We offer a range of services tailored to meet the unique needs of our customers, whether they're looking for residential internet services, IPTV services, or both. Although we currently do not deliver devices, our services are designed to be compatible with a variety of commonly available devices, providing our customers with flexibility and convenience. At SwitchTT, we believe in the power of connection, and strive to provide services that bring people together, no matter the distance.</p>

        </>
      </div>
    </>
  );
}

export default Company;
