import React from "react";
import ReactDOM from "react-dom/client";
import { Link, Navigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import axios from "axios";
import Cookies from "js-cookie";

const logo = require("../logo.png");
const visa = require("../VISA.png");
const mastercard = require("../Mastercard.png");
const EDS = require("../3dsecure_sw_45.png");
const FAC = require("../fac_sw_45.png");
const FTLOGO = require("../ft_logos.png");
const SWFTLOGO = require("../sw-ft-logo.png");

import "bootstrap/dist/css/bootstrap.min.css";

function SwFooter() {
  return (
    <>
      <div className="sw-ft-bottom iki-sw-footer  pe-3 ps-3 mt-1 pt-1 text-lg-start mt-4 col-12 col-lg-12">
        <div className="row d-flex ">
          <div className="col-12">
          <button className="btn fs-4 p-1 btn-link btn-sm"> <i className="mdi mdi-instagram"></i></button>
          <button className="btn fs-4 p-1 btn-link btn-sm"> <i className="mdi mdi-facebook"></i></button>
          <button className="btn fs-4 p-1 btn-link btn-sm"> <i className="mdi mdi-twitter"></i></button>
          <button className="btn fs-4 p-1 btn-link btn-sm me-4"> <i className="mdi mdi-youtube"></i></button>
          
          </div> 

          <div className="container">
            <div className="row">
                <div className="sw-ft-logo" style={{width:"70px", paddingLeft:"20px",  }}>
                    <img src={SWFTLOGO} alt="" className="sw-footer-logo mt-1" />
                </div>
                <div className="col-auto">
                    <ul className="col-12 fw-bold text-left mt-2 mb-0 mnu flex-wrap d-flex ps-0">
                        <li><a href="/about">Company</a></li>
                        <li><a href="/our-services">Our Services</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                    </ul>

                    <ul className="col-12 text-left mt-0 mb-3 mnu flex-wrap d-flex ps-0">
                        <li><a href="/refund-policy">Refund Poilicy</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/terms">Terms and Conditions</a></li>
                        <li><a href="/delivery-policy">Delivery Policy</a></li>
                        <li><a href="/security-policy">Security Policy</a></li>
                    </ul>
                </div>

            </div>
            
          </div>
          <div><span className="sw-copy">&copy; SwitchTT, 56FQ+Q36, Signal Hill, Tobago</span></div>
          
        </div>
      </div>
    </>
  );
}

export default SwFooter;
