import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function RefundPolicy() {
  return (
    <>
      <Navi />

      <div className="page-sw   col-lg-8 col-sm-12 col-md-12">
        <>
          <h1 className="fs-2 fw-bold mb-3">
            Refund Policy
          </h1>
          <h5>Introduction</h5>
          <p>
            At SwitchTT, we value our customers. This Refund Policy explains how
            and when we issue refunds for our internet and IPTV services
            ("Services").
          </p>

          <h5>Services Refund</h5>
          <p>
            If you are not 100% satisfied with your purchase, you can request a
            refund within 30 days from the purchase date. You will be refunded
            the full amount of the unused portion of the Services.
          </p>

          <h5>Process for Requesting Refunds</h5>
          <p>
            To request a refund, contact our customer service team at [Customer
            Service Contact Information]. We will process your refund as soon as
            we've confirmed all details and deemed that the conditions for a
            refund have been met.
          </p>

          <h5>Refund Exceptions</h5>
          <p>
            No refunds will be issued for services that have been fully consumed
            or completed. Furthermore, no refunds will be issued for services
            cancelled due to violations of our Terms of Use.
          </p>

          <h5>Changes to This Refund Policy</h5>
          <p>
            We may update our Refund Policy from time to time. We will notify
            you of any changes by posting the new Refund Policy on this page.
          </p>

          <h5>Contact Us</h5>
          <p>
            If you have any questions or comments about this Refund Policy, you
            can contact us at: [Contact Information]
          </p>
        </>
      </div>
    </>
  );
}

export default RefundPolicy;
