import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const api = axios.create({
  // https://api.switchtt.a2hosted.com
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://localhost:4001/api",
});

const crm = axios.create({
  //baseURL: 'https://sandbox.crm.com/backoffice/v1',
  baseURL: process.env.REACT_APP_API_URL + "/crmcom",
  // baseURL: 'https://switchtt.a2hosted.com/api/crmcom',
  //   baseURL: "https://localhost:4001/api/crmcom",
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
  },
});

function History() {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState({});
  const activeStep = 1;
  const [confirmationResponse, setConfirmationResponse] = useState("");
  const [showItems, setShowItems] = useState(false);
  const [confirmationResponseBackground, setConfirmationResponseBackground] =
    useState("alert alert-light");
  const [loading, setLoading] = useState(false);
  const [hideCartEmpty, setHideCartEmpty] = useState(false);
  const [orders, setOrders] = useState([]); 

  useEffect(() => {
    // Function to fetch data from API
    const fetchOrders = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user")) || null;

        if (user) {
          console.log(user.id);
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}/crmcom/contacts/${user.id}/order_history/`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
            data: { user: user.id },
          };
          const { data } = await axios.request(options);
          console.log(data);

          setOrders(data.content); // Save the data to state
          setLoading(false);
          setShowItems(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrders();
  }, []);

  const renderOrder = (order) => {
    let unix_timestamp = order.submitted_date;
    let date = new Date(unix_timestamp * 1000);
    let formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    // console.log(formattedDate);
    return (
      <div className="mt-2" key={order.id}>
        <div className="container">
          <div className="row border-bottom">
          <small>Date: {formattedDate}</small>

            <div className="col-4 col-md-3">
            <div className="pt-1 fs-6">
              </div>
              <div className="" key={order.number}>
                <span className="fs-6">Order #:</span> <span className="fw-bold fs-6">{order.number}</span>
              </div>
              
            </div>
            <div className="col-5 col-md-7">
              {Array.isArray(order.items) &&
                order.items.map((item) => (
                  <div className="pt-1" key={item.product.id}>
                    <div>{item.product.name}</div>
                  </div>
                ))}
            </div>

            <div className="col-3 col-md-2 text-right">
              {Array.isArray(order.items) &&
                order.items.map((item) => (
                  <div className="pt-1 text-right fs-6" key={item.product.id}>Paid: ${item.product.price_terms ? item.product.price : "N/A"}</div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navi />

      <div className="iki-form-desc text-center">
        <div
          className={confirmationResponseBackground + "p-0 m-0 pe-0 ps-0"}
          role="alert"
        ></div>
      </div>
      {/* use a toast instead  */}
      <div className="fs-2 fw-bold mb-3 col-lg-8 col-sm-12 col-md-12 text-secondary sw-hd-2">Order History</div>
      <div className="page-estimate   col-lg-8 col-sm-12 col-md-12">
        <Card className="mt-0">
          <Card.Header className="bg-transparent p-0 border-0 text-secondary fs-7"></Card.Header>
          <Card.Body className="p-0">
            <>
              {loading ? (
                <Row className="text-center">
                  <Col>
                    <div
                      className="page-loading iki-spinner spinner-border"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>

            {!loading && showItems ? (
              <>
                <div className="col-xs-12 sw-history">
                  {orders.map(renderOrder)}
                </div>
              </>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default History;
