import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function DeliveryPolicy() {
  return (
    <>
      <Navi />

      <div className="page-sw   col-lg-8 col-sm-12 col-md-12">
        <>
          <h1 className="fs-2 fw-bold mb-3">
            Delivery Policy
          </h1>
          <h5>Introduction</h5>
          <p>
            SwitchTT is primarily a provider of internet and IPTV services
            ("Services"). Our delivery policy pertains to these Services, as
            well as any potential future delivery of devices related to these
            Services. As of the current time, we do not deliver devices.
          </p>

          <h5>Service Delivery</h5>
          <p>
            Upon receipt of your order and successful payment, we will setup and
            activate your Services. Activation typically occurs within a few
            hours, but may take up to 24 hours. Once your Services are
            activated, you will receive confirmation via the email address
            provided during the registration process.
          </p>

          <h5>Device Delivery</h5>
          <p>
            At present, we do not deliver devices related to our Services. If
            this policy changes in the future, updated details regarding costs,
            delivery timelines, and policies will be posted on this page.
          </p>

          <h5>Changes to This Delivery Policy</h5>
          <p>
            We may update our Delivery Policy from time to time. We will notify
            you of any changes by posting the new Delivery Policy on this page.
          </p>

          <h5>Contact Us</h5>
          <p>
            If you have any questions or comments about this Delivery Policy,
            you can contact us at: [Contact Information]
          </p>
        </>
      </div>
    </>
  );
}

export default DeliveryPolicy;
