import React from "react";
import ReactDOM from "react-dom/client";
import { Link, Navigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import axios from 'axios';
import Cookies from 'js-cookie';

const logo = require("../logo.png");
const visa = require("../VISA.png");
const mastercard = require("../Mastercard.png");
const EDS = require("../3dsecure_sw_45.png");
const FAC = require("../fac_sw_45.png");
const FTLOGO = require("../ft_logos.png");
const SWFTLOGO = require("../sw-ft-logo.png");


import "bootstrap/dist/css/bootstrap.min.css";

function Footy() {

  const [comp, setComp] = useState("")

  useEffect(() => {
    setComp(location.pathname);
    console.log(location.pathname)
  }, [location]);

return (
    <>

    <footer className="sw-ft iki-sw-footer-light pb-2 mt-4 pt-1 text-center text-lg-start mt-4 col-12 col-lg-12">

        <div className="text-center  mb-4">
            {/* <img src={visa} alt="visa" className="footer-logo" />
            <img src={mastercard} alt="mastercard" className="footer-logo" /> */}
            {comp !== "/"  &&
              <span className="me-4"><img src={FAC} alt="FAC" className="footer-logo" /></span>
            }

             <img src={FTLOGO} alt="3D Secure" className="footer-logo mt-3" />
        </div>
    </footer>

    </>
  );
}

export default Footy;