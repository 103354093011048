import { React, Component, useState, useRef } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import Toast from "react-bootstrap/Toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
axios.defaults.withCredentials = true;

import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function TokenizationResult() {
    const [redirectTimer, setRedirectTimer] = useState(0); // 5 seconds countdown
    const [loading, setLoading] = useState(false);

  
    useEffect(() => {
      setLoading(true);

      if (redirectTimer > 0) {
        const timerId = setTimeout(() => setRedirectTimer(prevTimer => prevTimer - 1), 0);
        return () => clearTimeout(timerId);
      } else {
        window.location.href = '/checkout'; // redirect when the timer reaches 0
      }
    }, [redirectTimer]);
  
   
  
    return (

      <>
         {loading ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
                <h2>Processing</h2>
                 <p>Please wait.</p>
                <div style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center' 
                  }}>
                </div>
                <div className="page-loading iki-spinner spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
              </div>
            </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
            {/* <button style={{ padding: '10px 20px', marginTop: '20px', fontSize: '18px' }} onClick={handleButtonClick}>Return to Checkout</button> */}
          </div>
        )}
      
      
      
      </>
      
    );
  }


export default TokenizationResult;
