import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Privacy() {
  return (
    <>
      <Navi />

      <div className="page-sw   col-lg-8 col-sm-12 col-md-12">
        <>
          <h1 className="fs-2 fw-bold mb-3">
            Privacy Policy
          </h1>
          <h5>Introduction</h5>
          <p>
            SwitchTT is committed to protecting the privacy of our customers.
            This Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you use our internet and IPTV
            services ("Services").
          </p>

          <h5>Information We Collect</h5>
          <p>
            We may collect personal identification information such as names,
            email addresses, and phone numbers when you register for our
            Services. We also collect technical data, such as IP addresses,
            browser type, device information, usage data, and cookies.
          </p>

          <h5>How We Use Your Information</h5>
          <p>
            We use the information we collect to provide and improve our
            Services, to process transactions, to communicate with you, for
            customer service and technical support, and for other business
            purposes.
          </p>

          <h5>Information Sharing and Disclosure</h5>
          <p>
            We do not sell or rent your personal information to third parties.
            We may disclose your information to service providers and partners
            who assist us in operating our Services, conducting our business, or
            serving our users, so long as those parties agree to keep this
            information confidential. We may also release information to comply
            with the law, enforce our site policies, or protect ours or others'
            rights, property or safety.
          </p>

          <h5>Data Retention and Security</h5>
          <p>
            We retain your personal information for as long as necessary to
            provide the Services and fulfill the transactions you have
            requested, or for other essential purposes such as complying with
            our legal obligations. We use appropriate security measures to
            protect the security of your personal information.
          </p>

          <h5>Your Privacy Rights</h5>
          <p>
            You may request to review, update, or delete your personal
            information. You may also object to the processing of your personal
            information, ask us to restrict processing of your personal
            information or request portability of your personal information.
          </p>

          <h5>Changes to This Privacy Policy</h5>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>

          <h5>Contact Us</h5>
          <p>
            If you have any questions or comments about this Privacy Policy, you
            can contact us at: [Contact Information]
          </p>
        </>
      </div>
    </>
  );
}

export default Privacy;
