import { React, Component, useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navi from "./nav";
import ProgressBar from "./progressBar";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function SecurityPolicy() {
  return (
    <>
      <Navi />

      <div className="page-sw   col-lg-8 col-sm-12 col-md-12">
        <>
          <h1 className="fs-2 fw-bold mb-3 ">Security Policy</h1>
          <p>
            At SwitchTT, we take the security of our customers' payment card
            details very seriously. To ensure the safe transmission and storage
            of payment card details, we have implemented robust security
            measures and practices in line with global standards.
          </p>

          <h5>Data Transmission Security</h5>
          <p>
            All payment card details are transmitted over secure networks. We
            use Secure Socket Layer (SSL) technology, an encryption protocol
            that protects sensitive information as it crosses the internet. With
            SSL, payment card details are encrypted before being transmitted,
            ensuring they cannot be intercepted or read by unauthorized parties.
          </p>

          <h5>Compliance with PCI DSS</h5>
          <p>
            We comply with the Payment Card Industry Data Security Standard (PCI
            DSS), a set of security standards designed to ensure all companies
            that accept, process, store or transmit credit card information
            maintain a secure environment. Compliance with PCI DSS means we
            adhere to high security standards in handling and storing the
            payment card information of our customers.
          </p>

          <h5>Security Policies and Procedures</h5>
          <p>
            Our team is trained in secure data handling and follows strict
            protocols when dealing with payment card information. Access to
            payment card details is limited to authorized personnel only, and is
            granted on a 'need-to-know' basis. Regular audits are conducted to
            ensure compliance with our security policies and procedures.
          </p>

          <p>
            Despite our robust security measures, we encourage our customers to
            also take steps to protect their payment card details. This includes
            keeping their devices secure, regularly updating passwords, and not
            sharing sensitive information via insecure communication channels.
          </p>

          <p>
            Your trust is important to us, and we are committed to maintaining
            the highest levels of security to protect your payment card details.
            If you have any questions or concerns about our Payment Card
            Security Policy, please do not hesitate to contact us.
          </p>
        </>
      </div>
    </>
  );
}

export default SecurityPolicy;
