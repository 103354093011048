import React, { useState } from "react";
import "./ProgressBar.css";

function ProgressBar(activeStep) {
  const step = activeStep.activeStep || 0;

  // console.log(step);

  const nextStep = () => {
    if (step < 3) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <div className="mb-3 sw-p-bar">
      {/* <dic className="line-container"> */}
        <div className="sw-line"></div>
      {/* </dic> */}
      <div className="sw-progress-bar justify-content-between">
        <div className="d-flex flex-column justify-content-between">
          <div
            className={`sw-fs text-center sw-circle ${step === 1 ? "sw-active" : ""}`}
          >
            1
          </div>
          <div className="sw-fs  sw-t mt-1">Payment Amount</div>
        </div>
        <div className="d-flex flex-column ">
          <div className={`sw-fs sw-circle ${step === 2 ? "sw-active" : ""}`}>2</div>
          <div className="sw-fs sw-t  mt-1">Payment Method</div>
        </div>
        <div className="d-flex flex-column ">
          <div className={`sw-fs sw-circle ${step === 3 ? "sw-active" : ""}`}>3</div>
          <div className="sw-fs sw-t mt-1">Review Payment</div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
